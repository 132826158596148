export const GET_COUNTRY = 'GET_COUNTRY';

export interface Country {
  id: string;
  name: string;
}

export interface CountryState {
  country: Country[] | [];
}

interface GetCountryAction {
  type: typeof GET_COUNTRY;
  payload: Country[];
}

export type CountryActionTypes = GetCountryAction;
