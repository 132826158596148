import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { cancelBooking } from '../../../store/booking/booking.actions';
import { BookingContext } from '../../../context/booking.ctx';

interface Props {
  onSuccess: () => void;
}

const Cancel = ({ onSuccess }: Props) => {
  const { reservation } = useContext(BookingContext);
  const [t] = useTranslation();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleClick = async () => {
    try {
      setSubmitting(true);
      await cancelBooking(reservation!);
      onSuccess();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <div className="Cancel">
      <h2>{t('booking.cancelTitle')}</h2>
      <p>{t('booking.cancelSubtitle')}</p>
      <Button
        onClick={handleClick}
        label={t('booking.yesConfirm')}
        submitting={submitting}
      />
    </div>
  );
};

export default Cancel;
