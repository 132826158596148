import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikHelpers, FormikValues } from 'formik';
import { toastUtil } from '../../../utils/toast.utils';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button';
import AvatarImage from '../../../components/AvatarImage';
import useWithDispatch from '../../../hooks/useWithDispatch';
import useWithSelection from '../../../hooks/useWithSelection';
import { updateCurrentCustomer } from '../../../store/customer/customer.actions';
import { customerSelector } from '../../../store/customer/customer.selectors';
import { loungeSelector } from '../../../store/booking/booking.selectors';
import { formModel, loungeField } from './formModel';
import validationSchema from './validationSchema';

import './ProfileForm.scss';

const ProfileForm = () => {
  const customer = useWithSelection(customerSelector);
  const lounge = useWithSelection(loungeSelector);
  const {
    nickname,
    first_name,
    last_name,
    email,
    default_lounge_id,
    address1,
    phone_number
  } = customer;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [t] = useTranslation();
  const updateCustomerAction = useWithDispatch(updateCurrentCustomer);
  const loungeField2 = useMemo(() => {
    return { ...loungeField, list: lounge };
  }, [lounge]);

  const formik = useFormik({
    initialValues: {
      nickname,
      first_name,
      last_name,
      email,
      default_lounge_id,
      address1,
      phone_number
    },
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        const response = await updateCustomerAction(values);
        setSubmitting(false);
        console.log(response);
        toastUtil('success', response.meta.message);
      } catch (err) {
        console.log(err);

        setSubmitting(false);
        if (err?.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (err && err.meta) toastUtil('error', err.meta.message);
      }
    }
  });

  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <div className="ProfileForm">
      <h2>{t('profile.myProfile')}</h2>
      <AvatarImage />
      <form onSubmit={formik.handleSubmit}>
        <div>
          {formModel.map((field) => (
            <FormField
              key={field.name}
              i18nKey={field.i18nKey}
              type={field.type}
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
              {...formik.getFieldProps(field.name)}
            />
          ))}
          <FormField
            i18nKey={loungeField2.i18nKey}
            type={loungeField2.type}
            list={loungeField2.list}
            errors={formik.errors}
            touched={formik.touched}
            setFieldValue={formik.setFieldValue}
            {...formik.getFieldProps(loungeField2.name)}
          />
          <div className="Profile-form-footer">
            <Button
              type="submit"
              label={t('profile.submit')}
              disabled={submitting || !isValidForm}
              submitting={submitting}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
