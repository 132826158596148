import {
  CustomerState,
  CustomerActionTypes,
  SET_CURRENT_MEMBER,
  REMOVE_CURRENT_MEMBER,
  UPDATE_CURRENT_MEMBER,
  LOGIN,
  LOGOUT,
  Customer
} from './customer.types';

let customerStore = null;
if (window.localStorage) {
  const savedState = JSON.parse(localStorage.getItem('lapState')!);
  customerStore = savedState ? savedState.customer : null;
}

const initialState: CustomerState = {
  currentCustomer: customerStore?.currentCustomer || null,
  customers: customerStore?.customers || []
};

export default (state = initialState, action: CustomerActionTypes) => {
  switch (action.type) {
    case LOGOUT:
      const currentCustomerId = state.currentCustomer?.id;
      const filteredCustomers = state.customers.filter(
        (u: Customer) => u.id !== currentCustomerId
      );

      return {
        currentCustomer: null,
        customers: filteredCustomers
      };
    case LOGIN:
      const loggedInCustomer = state.customers.find(
        (u: Customer) => u.id === action.payload.id
      );
      let customers = [...state.customers];

      if (!loggedInCustomer) customers.push(action.payload);

      return {
        currentCustomer: action.payload,
        customers
      };
    case SET_CURRENT_MEMBER:
      return {
        ...state,
        currentCustomer: { ...action.payload }
      };
    case REMOVE_CURRENT_MEMBER:
      return {
        ...state,
        currentCustomer: null
      };
    case UPDATE_CURRENT_MEMBER:
      return {
        currentCustomer: { ...state.currentCustomer, ...action.payload },
        customers: state.customers.map((customer) => {
          if (customer.id !== action.payload.id) return customer;
          return { ...customer, ...action.payload };
        })
      };
    default:
      return state;
  }
};
