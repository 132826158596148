import { FormModelField } from '../../../types/form.types';

interface Values {
  nickname: string;
  first_name: string;
  last_name: string;
  email: string;
  address1: string;
  phone_number: string;
}

export const initialValues: Values = {
  nickname: '',
  first_name: '',
  last_name: '',
  email: '',
  address1: '',
  phone_number: ''
};

export const formModel: FormModelField[] = [
  {
    name: 'nickname',
    i18nKey: 'profile.nickname',
    type: 'text'
  },
  {
    name: 'first_name',
    i18nKey: 'profile.first_name',
    type: 'text'
  },
  {
    name: 'last_name',
    i18nKey: 'profile.last_name',
    type: 'text'
  },
  {
    name: 'email',
    i18nKey: 'profile.email',
    type: 'text'
  },
  {
    name: 'address1',
    i18nKey: 'profile.address1',
    type: 'text'
  },
  {
    name: 'phone_number',
    i18nKey: 'profile.phone_number',
    type: 'text'
  }
];

export const loungeField: FormModelField = {
  name: 'default_lounge_id',
  i18nKey: 'profile.default_lounge_id',
  type: 'select',
  list: []
};
