import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useWithSelection from '../../../hooks/useWithSelection';
import { SetStateType } from '../../../types/utils.types';
import { loungeSelector } from '../../../store/booking/booking.selectors';
import {
  getMemberships,
  getTimeSlots,
  getTimesForLounge
} from '../../../store/booking/booking.actions';
import {
  Membership,
  Lounge,
  TimeSlot
} from '../../../store/booking/booking.types';
import Button from '../../../components/Button';
import FilterSelect from './FilterSelect';
import Sims from '../Sims';
import RidesSharing from '../RidesSharing';
import Slots from '../Slots';
import './Filters.scss';

interface Props {
  lounge: string;
  setLounge: SetStateType<string>;
  membership: string;
  setMembership: SetStateType<string>;
  time: string;
  setTime: SetStateType<string>;
  sims: number;
  setSims: SetStateType<number>;
  ridesSharing: boolean;
  setRidesSharing: SetStateType<boolean>;
  slot: string | null;
  setSlot: SetStateType<string | null>;
  onClick: (duration: number) => void;
}

const Filters = ({
  lounge,
  setLounge,
  membership,
  setMembership,
  time,
  setTime,
  sims,
  setSims,
  ridesSharing,
  setRidesSharing,
  slot,
  setSlot,
  onClick
}: Props) => {
  const shouldFetch = useRef<boolean>(true);
  const first = useRef<boolean>(true);
  const [t] = useTranslation();
  const lounges = useWithSelection(loungeSelector);
  const [loungeMemberships, setLoungeMemberships] = useState<
    Membership[]
  >([]);
  const [loungeTimes, setLoungeTimes] = useState<string[]>([]);
  const [loungeSlots, setLoungeSlot] = useState<TimeSlot[]>([]);

  const getLoungeSims = (loungeId: string) =>
    lounges.find((l: Lounge) => l.id === String(loungeId))?.max_group_size;

  const handleClick = () => {
    const duration = loungeSlots.find((s: TimeSlot) => s.id === slot);
    onClick(duration?.duration_in_minutes || 15);
  };

  const getSlots = async () => {
    try {
      const res = await getTimeSlots(lounge, sims, membership);
      setLoungeSlot(res);
      if (!res.find((s: TimeSlot) => s.id === slot)) setSlot(null);
    } catch (err) {}
  };

  // Filters useEffect
  useEffect(() => {
    if (!lounge) return;
    //get memberships and times for base center
    (async () => {
      try {
        const res = await Promise.all([
          getMemberships(lounge),
          getTimesForLounge(lounge, time ? time.toString() : '')
        ]);
        setLoungeMemberships(res[0]);
        setLoungeTimes(res[1]);
        // check if base center has selected membership
        const membershipExist = res[0].find(
          (m: Membership) => m.id === String(membership)
        );
        if (!membershipExist) setMembership('');
        // check if base center has selected time
        const timeExists = res[1].find((t: string) => t === time);
        if (!timeExists) setTime('');
      } catch (err) {}
    })();
    // eslint-disable-next-line
  }, [lounge]);

  useEffect(() => {
    if (first.current) return;

    if (sims > 1 && shouldFetch.current) {
      getSlots();
      shouldFetch.current = false;
    }
    if (sims === 1) {
      shouldFetch.current = true;
      getSlots();
    }
    // eslint-disable-next-line
  }, [sims]);

  // Filters useEffect
  useEffect(() => {
    //getTimeSlots
    if (!lounge) return;
    getSlots();
    first.current = false;
    // eslint-disable-next-line
  }, [lounge, membership]);

  return (
    <div className="Filters">
      <h4>{t('calendar.filter')}</h4>
      <FilterSelect
        label={t('calendar.lounge')}
        list={lounges}
        setValue={setLounge}
        value={lounge}
        noEmptyValue
      />
      <FilterSelect
        label={t('calendar.showFor')}
        list={loungeMemberships}
        setValue={setMembership}
        value={membership}
      />
      <FilterSelect
        label={t('calendar.time')}
        timeList={loungeTimes}
        setValue={setTime}
        value={time}
      />
      <Sims
        sims={sims}
        setSims={setSims}
        available={getLoungeSims(lounge) || 1}
      />
      <Slots slots={loungeSlots} setSlot={setSlot} selected={slot} />
      <RidesSharing ridesSharing={ridesSharing} setRidesSharing={setRidesSharing} groupRide={sims > 1} />
      <Button label={t('calendar.showSlots')} onClick={handleClick} />
    </div>
  );
};

export default Filters;
