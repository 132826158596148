import { CountryState, CountryActionTypes, GET_COUNTRY } from './country.types';

const initialState: CountryState = {
  country: []
};

export default (state = initialState, action: CountryActionTypes) => {
  switch (action.type) {
    case GET_COUNTRY:
      return {
        ...state,
        country: [...action.payload] 
      };

    default:
      return state;
  }
};
