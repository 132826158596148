import { FormModelField } from '../../../types/form.types';

interface Values {
  password: string;
  pin_code: string;
}

export const initialValues: Values = {
  password: '',
  pin_code: ''
};

export const formModel: FormModelField[] = [
  {
    name: 'password',
    i18nKey: 'profile.password',
    type: 'password'
  },
  {
    name: 'pin_code',
    i18nKey: 'profile.pin_code',
    type: 'password'
  }
];
