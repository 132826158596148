import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';
import Button from '../Button';
import {
  ReservationDetails
  // ReservationDetailsCustomer
} from '../../store/booking/booking.types';
import { checkIn, deleteCoupon } from '../../store/booking/booking.actions';
import { BookingContext } from '../../context/booking.ctx';
import { SetStateType } from '../../types/utils.types';
import icon from '../../images/arrow-link-back.svg';
import cancelIcon from '../../images/trash.svg';
import removeIcon from '../../images/cancel.svg';
import couponIcon from '../../images/coupon.svg';
import couponIconAlt from '../../images/coupon-alt.svg';

// import {ReservationDetailsCustomer} from '../../store/booking/booking.types';

import './BookingDetails.scss';

interface Props {
  booking: ReservationDetails | null;
  setPage: SetStateType<number>;
  setBooking: SetStateType<null | ReservationDetails>;
}

const langs: any = {
  de,
  en,
  es
};

const Details = ({ booking, setPage, setBooking }: Props) => {
  const [t, i18n] = useTranslation();
  const { setCheckedInId } = useContext(BookingContext);
  const { closeDetails, openCancelDialog } = useContext(BookingContext);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const removeCoupon = async () => {
    try {
      const data = await deleteCoupon(booking!.id);
      setBooking(data);
    } catch {}
  };

  const handleCheckIn = async () => {
    try {
      if (booking!.number_of_simulators > 1) {
        setPage(1);
        return;
      }
      setSubmitting(true);
      await checkIn(booking!.id);
      setBooking({ ...booking!, status: 'checked-in' });
      setCheckedInId(booking!.id);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  if (!booking) return null;

  return (
    <div className="BookingDetails__info">
      <h2>{t('booking.bookingDetails')}</h2>
      <div className="BookingDetails__row BookingDetails__row--customers">
        <span>{t('booking.customer')}</span>
        <span>
          {/* {booking && !booking.group_ride_customers.length && ( */}
          {booking && (
            <Link to={`/${booking.customer.id}`} onClick={closeDetails}>
              {booking.customer.full_name} <img src={icon} alt="" />
            </Link>
          )}
          {/* {booking &&
            booking.group_ride_customers.length > 0 &&
            booking.group_ride_customers.map((u: ReservationDetailsCustomer) => (
              <Link key={u.id} to={`/${u.id}`} onClick={closeDetails}>
                {u.full_name} <img src={icon} alt="" />
              </Link> */}
          {/* ))} */}
        </span>
      </div>
      {booking?.note && (
        <div className="BookingDetails__row BookingDetails__row--note">
          <span>{t('booking.note')}</span>
          <span>{booking.note}</span>
        </div>
      )}
      <div className="BookingDetails__row">
        <span>{t('booking.lounge')}</span>
        <span>{booking.lounge.name}</span>
      </div>
      <div className="BookingDetails__row">
        <span>{t('booking.time')}</span>
        <span>
          {format(new Date(booking.start_time), 'HH:mm')} -{' '}
          {format(new Date(booking.end_time), 'HH:mm')}
        </span>
      </div>
      <div className="BookingDetails__row">
        <span>{t('booking.duration')}</span>
        <span>{booking.duration_in_minutes} min</span>
      </div>
      <div className="BookingDetails__row">
        <span>{t('booking.sims')}</span>
        <span>x {booking.number_of_simulators}</span>
      </div>
      <div className="BookingDetails__row">
        <span>{t('booking.date')}</span>
        <span>
          {format(new Date(booking.start_time), 'EEEE, do MMMM', {
            locale: langs[i18n.language]
          })}
        </span>
      </div>
      <div className="BookingDetails__row">
        <span>{t('booking.paymentMethod')}</span>
        <span>{booking.payment_method}</span>
      </div>
      <div className="BookingDetails__row">
        <span>{t('booking.createdBy')}</span>
        <span>{booking.created_by}</span>
      </div>
      <div className="BookingDetails__row">
        <span>{t('calendar.sharedRides')}</span>
        <span>{booking.rides_sharing ? booking.shared_rides : t('calendar.ridesSharingDisabled')}</span>
      </div>
      {booking.coupon && (
        <div className="BookingDetails__row BookingDetails__row--coupon">
          <span>{t('booking.couponInput.label')}</span>
          <span>
            {booking.coupon.discount_label} <br /> {booking.coupon.description}
          </span>
          {booking?.status === 'pending' && (
            <img src={removeIcon} alt="Remove Coupon" onClick={removeCoupon} />
          )}
        </div>
      )}
      <div className="BookingDetails__row total">
        <span>{t('booking.total')}</span>
        <span>
          {booking.invoice && (
            <span>{booking.invoice.total} {booking.invoice.currency}</span>
          )}
        </span>
      </div>
      {booking?.status === 'pending' && (
        <img
          className="cancelIcon"
          src={cancelIcon}
          alt="cancelIcon"
          onClick={() => openCancelDialog()}
        />
      )}

      <div className="BookingInfo__footer">
        {booking?.status === 'pending' && (
          <Button
            label={t('booking.checkIn')}
            onClick={handleCheckIn}
            submitting={submitting}
          />
        )}
        {booking?.status === 'checked-in' && (
          <Button label={t('booking.checkedIn')} disabled={true} />
        )}
        {booking?.status === 'completed' && (
          <Button label={t('booking.completed')} disabled={true} />
        )}
        <button
          className="BookingInfo__coupon"
          disabled={!booking.coupon_applicable}
          onClick={() => setPage(2)}
        >
          <img
            src={booking?.coupon_applicable ? couponIcon : couponIconAlt}
            alt=""
          />
        </button>
      </div>
    </div>
  );
};

export default Details;
