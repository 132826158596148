import { FormikHelpers, FormikValues, useFormik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import FormField from '../../../components/FormField';
import useWithSelection from '../../../hooks/useWithSelection';
import { loungeSelector } from '../../../store/booking/booking.selectors';
import {
  updateCustomer
} from '../../../store/customers/customers.actions';
import {
  CustomerDetails
} from '../../../store/customers/customers.types';
import { toastUtil } from '../../../utils/toast.utils';
import { formModel } from './formModel';
import validationSchema from './validationSchema';

import { format } from 'date-fns';
import { genderCodeSelector } from '../../../store/code/code.selectors';
import { countrySelector } from '../../../store/country/country.selectors';
import { getLoungeSimulators } from '../../../store/simulator/simulator.actions';
import { Simulator } from '../../../store/simulator/simulator.types';
import './EditForm.scss';

interface Props {
  customer: CustomerDetails | null;
}

interface Params {
  id: string;
}

const EditForm = ({ customer }: Props) => {
  const { id } = useParams<Params>();
  const lounges = useWithSelection(loungeSelector);
  const genders = useWithSelection(genderCodeSelector);
  const countries = useWithSelection(countrySelector);
  const [simulators, setSimulators] = useState<Simulator[]>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedDob, setSelectedDob] = useState<Date>(); 
  const [t] = useTranslation();

  if (customer == null) {
    customer = {} as CustomerDetails;
  }

  const {
    nickname,
    first_name,
    last_name,
    email,
    default_lounge_id,
    address1,
    address2,
    city,
    zip_code,
    gender_id,
    country_id,
    pob_country_id,
    company,
    body_weight,
    body_height,
    dob,
    fav_lounge_id,
    fav_simulator_id,
    preferred_language,
    phone_number
  } = customer;

  const formik = useFormik({
    initialValues: {
      nickname,
      first_name,
      last_name,
      email,
      default_lounge_id,
      address1,
      address2,
      city,
      zip_code,
      gender_id,
      country_id,
      pob_country_id,
      company,
      body_weight,
      body_height,
      dob,
      fav_lounge_id,
      fav_simulator_id,
      preferred_language,
      phone_number
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        await updateCustomer(id, values);
        setSubmitting(false);
      } catch (err) {
        console.log(err);

        setSubmitting(false);
        if (err?.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (err && err.meta) toastUtil('error', err.meta.message);
      }
    }
  });

  const fetchSimulators = async (lounge_id: string) => {
    setSimulators([]);
    formik.setFieldValue('fav_simulator_id', "");
    if (!lounge_id) {
      return;
    }
    try {
      const res = await getLoungeSimulators(lounge_id);
      setSimulators(res);
    } catch (err) { }
  };

  useEffect(() => {
    if (dob) {
      setSelectedDob(new Date(dob));
    }
    fetchSimulators(fav_lounge_id);
    // eslint-disable-next-line
  }, [fav_lounge_id]);

  formModel[formModel.findIndex(x => x.name === 'email')]['disabled'] = true;
  formModel[formModel.findIndex(x => x.name === 'country_id')]['list'] = countries;
  formModel[formModel.findIndex(x => x.name === 'pob_country_id')]['list'] = countries;
  formModel[formModel.findIndex(x => x.name === 'default_lounge_id')]['list'] = lounges;
  formModel[formModel.findIndex(x => x.name === 'default_lounge_id')]['disabled'] = true;
  formModel[formModel.findIndex(x => x.name === 'gender_id')]['list'] = genders;
  formModel[formModel.findIndex(x => x.name === 'fav_simulator_id')]['list'] = simulators;
  formModel[formModel.findIndex(x => x.name === 'fav_lounge_id')]['list'] = lounges;
  formModel[formModel.findIndex(x => x.name === 'fav_lounge_id')]['onChange'] = (evt: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(evt.target.name, evt.target.value);
    fetchSimulators(evt.target.value);
  };

  formModel[formModel.findIndex(x => x.name === 'dob')]['selectedDate'] = selectedDob;
  formModel[formModel.findIndex(x => x.name === 'dob')]['onChange'] = (name: string, date: Date) => {
      formik.setFieldValue(name, date ? format(date, 'yyyy-MM-dd'): '');
      setSelectedDob(date);
  };

  const isValidForm = validationSchema.isValidSync({ ...formik.values });
  const disabled = false;

  return (
    <form onSubmit={formik.handleSubmit} className="EditForm">
      <div className="EditForm__header">
        <div className="EditForm__img">
          {customer && <img src={customer.profile_pic_url} alt="" />}
        </div>
        <Button
          type="submit"
          label={t('customers.save')}
          disabled={submitting || !isValidForm || disabled}
          submitting={submitting}
          small
        />
      </div>
      <div className="EditForm__body">
        {formModel.map((field) => (
          <FormField
            key={field.name}
            i18nKey={field.i18nKey}
            type={field.type}
            errors={formik.errors}
            touched={formik.touched}
            setFieldValue={formik.setFieldValue}
            list={field.list}
            disabled={field.disabled}
            required={field.required}
            {...formik.getFieldProps(field.name)}
            onChange={field.onChange || formik.getFieldProps(field.name).onChange}
            selectedDate={field.selectedDate}
          />
        ))}
      </div>
    </form>
  );
};

export default EditForm;
