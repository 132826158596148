import { FormModelField } from '../../../types/form.types';

interface Values {
  nickname: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  default_lounge_id: string;
  address1: string;
  address2: string;
  country_id: string;
  gender_id: string;
  city: string;
  dob: string;
}

export const initialValues: Values = {
  nickname: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  default_lounge_id: '',
  address1: '',
  address2: '',
  country_id: '',
  gender_id: '',
  city: '',
  dob: '',
};

export const formModel: FormModelField[] = [
  {
    name: 'nickname',
    i18nKey: 'profile.nickname',
    type: 'text',
    required: true 
  },
  {
    name: 'first_name',
    i18nKey: 'profile.first_name',
    type: 'text',
    required: true 
  },
  {
    name: 'last_name',
    i18nKey: 'profile.last_name',
    type: 'text',
    required: true 
  },
  {
    name: 'email',
    i18nKey: 'profile.email',
    type: 'text',
    required: true 
  },
  {
    name: 'phone_number',
    i18nKey: 'profile.phone_number',
    type: 'text'
  },
  {
    name: 'default_lounge_id',
    i18nKey: 'profile.default_lounge_id',
    type: 'select',
    list: []
  },
  {
    name: 'company',
    i18nKey: 'profile.company',
    type: 'text'
  },
  {
    name: 'body_weight',
    i18nKey: 'profile.body_weight',
    type: 'number'
  },
  {
    name: 'body_height',
    i18nKey: 'profile.body_height',
    type: 'number'
  },
  {
    name: 'address1',
    i18nKey: 'profile.address1',
    type: 'text',
    required: true 
  },
  {
    name: 'address2',
    i18nKey: 'profile.address2',
    type: 'text'
  },
  {
    name: 'country_id',
    i18nKey: 'profile.country_id',
    type: 'select',
    list: [],
    required: true 
  },
  {
    name: 'city',
    i18nKey: 'profile.city',
    type: 'text',
    required: true 
  },
  {
    name: 'zip_code',
    i18nKey: 'profile.zip_code',
    type: 'text'
  },
  {
    name: 'gender_id',
    i18nKey: 'profile.gender_id',
    type: 'select',
    list: []
  },
  {
    name: 'dob',
    i18nKey: 'profile.dob',
    type: 'datepicker'
  },
  {
    name: 'pob_country_id',
    i18nKey: 'profile.pob_country_id',
    type: 'select',
    list: [],
    required: true 
  },
  {
    name: 'fav_lounge_id',
    i18nKey: 'profile.fav_lounge_id',
    type: 'select',
    list: []
  },
  {
    name: 'fav_simulator_id',
    i18nKey: 'profile.fav_simulator_id',
    type: 'select',
    list: []
  },
  {
    name: 'preferred_language',
    i18nKey: 'profile.preferred_language',
    type: 'select',
    list: [
      {
        id: 'en',
        label: 'EN'
      },
      {
        id: 'de',
        label: 'DE'
      },
      {
        id: 'es',
        label: 'ES'
      }
    ]
  }
];