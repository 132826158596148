import React from 'react';
// import useWithSelection from '../../hooks/useWithSelection';
// import { customerSelector } from '../../store/customer/customer.selectors';

import ProfileForm from './ProfileForm';
import ChangePass from './ChangePass';
import ChangePin from './ChangePin';
import './Profile.scss';

const Profile = () => {
  return (
    <div className="Profile">
      <ProfileForm />
      <div className="Profile__cards">
        <ChangePass />
        <ChangePin />
      </div>
    </div>
  );
};

export default Profile;
