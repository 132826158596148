import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paging } from '../../store/customers/customers.types';
import { SetStateType } from '../../types/utils.types';
import Size from './Size';
import { ReactComponent as FirstIcon } from '../../images/first.svg';
import { ReactComponent as LasttIcon } from '../../images/last.svg';
import { ReactComponent as NextIcon } from '../../images/next.svg';
import { ReactComponent as PrevtIcon } from '../../images/previous.svg';

import './CustomersFooter.scss';

interface Props {
  paging: Paging | null;
  setPage: SetStateType<number>;
  setSize: SetStateType<number>;
  size: number;
  page: number;
}

const CustomersFooter = ({ paging, setPage, setSize, size, page }: Props) => {
  const [t] = useTranslation();

  const firstPage = () => setPage(1);
  const prevPage = () => {
    if (page > 1) setPage(page - 1);
  };
  const nextPage = () => {
    if (!paging || page >= paging.pages) return;
    setPage(page + 1);
  };
  const lastPage = () => {
    setPage(paging?.pages || 1);
  };

  return (
    <div className="CustomersFooter">
      <Size size={size} setSize={setSize} />
      <div className="CustomersFooter__pager">
        <span>
          {size * (page - 1) + 1} -{' '}
          {paging?.total_count && size * page > paging?.total_count
            ? paging.total_count
            : size * page}{' '}
          {t('customers.of')} {paging && paging.total_count}
        </span>
        <FirstIcon onClick={firstPage} />
        <PrevtIcon onClick={prevPage} />
        <NextIcon onClick={nextPage} />
        <LasttIcon onClick={lastPage} />
      </div>
    </div>
  );
};

export default CustomersFooter;
