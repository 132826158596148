import React, { useEffect, useState, useRef, MouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getCustomer,
  getInvoicedPlans,
  getCustomerReservations,
  activateCustomer,
  activateAndResetCustomer
} from '../../store/customers/customers.actions';
import {
  CustomerDetails,
  InvoicedPlan,
  RidesHistory,
  Paging
} from '../../store/customers/customers.types';
import EditForm from './EditForm';
import MembershipDetails from './MembershipDetails';
import Button from '../../components/Button';
import Notes from './Notes';
import History from './History';
import CustomersFooter from '../../components/CustomersFooter';

import './CustomerProfile.scss';

interface Params {
  id: string;
}

const CustomerProfile = () => {
  const first = useRef(true);
  const { id } = useParams<Params>();
  const [t] = useTranslation();
  const [customer, setCustomer] = useState<CustomerDetails | null>(null);
  const [plans, setPlans] = useState<InvoicedPlan[]>([]);
  const [rides, setRides] = useState<RidesHistory[]>([]);
  const [meta, setMeta] = useState<Paging | null>(null);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(8);

  const fetchPlans = async () => {
    try {
      const res = await getInvoicedPlans(id);
      setPlans(res.reverse());
    } catch (error) { }
  };

  const fetchReservations = async () => {
    try {
      const { data, meta } = await getCustomerReservations(
        id,
        page,
        size
      );
      setRides(data);
      setMeta(meta);
    } catch (error) { }
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const res = await Promise.all([
          getCustomer(id),
          getInvoicedPlans(id)
        ])
        setCustomer(res[0]);
        setPlans(res[1].reverse());
      } catch (error) { }
    })();
  }, [id]);

  useEffect(() => {
    if (first.current) return;
    page === 1 ? fetchReservations() : setPage(1);
    // eslint-disable-next-line
  }, [size]);

  useEffect(() => {
    first.current = false;
    fetchReservations();
    // eslint-disable-next-line
  }, [page]);


  const onActivate = async () => {
    try {
      await activateCustomer(id);
    } catch (error) {
      console.warn(error);
    }
  };

  const onActivateAndReset = async (e: MouseEvent) => {
    try {
      let element = e.target as HTMLElement;
      if (await activateAndResetCustomer(id)) {
        element.parentElement && (element.parentElement.innerHTML = '');
      };
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div className="CustomerProfile">
      <div className="CustomerProfile__heading">
        <h2>{t('customers.customersProfile')}</h2>
        <div className="CustomerProfile__headingButtons">
          {!customer?.active && (
            <Button
              label={'Request Activation'}
              secondary
              small
              onClick={onActivate}
            />
          )}

          {!customer?.active && (
            <Button
              label={'Activate and reset password'}
              secondary
              small
              onClick={onActivateAndReset}
            />
          )}
        </div>
      </div>
      <div className="CustomerProfile__content">
        <div className="CustomerProfile__sidebar">
          <EditForm customer={customer} />
        </div>
        <div className="CustomerProfile__main">
          <MembershipDetails
            plan={customer?.membership_plan}
            onUpgradeCallback={fetchPlans}
          />
          <Notes />
          <History plans={plans} rides={rides}>
            <CustomersFooter
              paging={meta}
              setPage={setPage}
              setSize={setSize}
              size={size}
              page={page}
            />
          </History>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
