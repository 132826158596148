import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CloseDialog from '../../../components/CloseDialog';
import { SetStateType } from '../../../types/utils.types';
import { getCustomerFilters } from '../../../store/customers/customers.actions';
import { Filters } from '../../../store/customers/customers.types';
import Option from '../Option';

interface Props {
  filterWith: string;
  setFilterWith: SetStateType<string>;
}

const BaseFilter = ({ filterWith, setFilterWith }: Props) => {
  const [t] = useTranslation();
  const [filters, setFilters] = useState<Filters | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await getCustomerFilters();
        setFilters(data);
      } catch (error) {}
    })();
  }, []);

  return (
    <>
      <span
        className={cx('Customers__pill', { active: !!filterWith })}
        onClick={show}
      >
        {t('customers.show')}: {!filterWith && t('customers.all')}
        {filterWith && filters && filters[filterWith]}
      </span>
      <Dialog onClose={hide} open={open} className="ru-dialog">
        <CloseDialog onClick={hide} />
        <div className="Customers__filter-options">
          <h2>{t('customers.show')}</h2>
          <Option
            value={''}
            setValue={setFilterWith}
            callback={hide}
            isSelected={!filterWith}
          >
            {t('customers.all')}
          </Option>
          {filters &&
            Object.keys(filters).map((k) => (
              <Option
                setValue={setFilterWith}
                value={k}
                callback={hide}
                isSelected={filterWith === k}
                key={k}
              >
                {filters[k]}
              </Option>
            ))}
        </div>
      </Dialog>
    </>
  );
};

export default BaseFilter;
