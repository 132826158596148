import React from 'react';
import cx from 'classnames';
import Loader from '../Loader';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  label: string;
  disabled?: boolean;
  submitting?: boolean;
  secondary?: boolean;
  inverted?: boolean;
  white?: boolean;
  small?: boolean;
  onClick?: any;
}

const Button = ({
  type = 'button',
  label,
  disabled,
  submitting,
  secondary,
  inverted,
  white,
  small,
  onClick = () => {}
}: Props) => (
  <button
    type={type}
    className={cx('btn', {
      secondary,
      primary: !secondary,
      inverted,
      white,
      s: small
    })}
    disabled={submitting || disabled}
    onClick={onClick}
  >
    {submitting ? (
      <Loader color={secondary ? 'dark-blue' : 'neon'} width={20} height={20} />
    ) : (
      label
    )}
  </button>
);

export default Button;
