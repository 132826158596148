import React, { useContext } from 'react';
import { BookingContext } from '../../context/booking.ctx';
import './InvoiceMoreInfo.scss';
import { useTranslation } from 'react-i18next';

const InvoiceMoreInfo = () => {
  const [t] = useTranslation();
  const { invoice } = useContext(BookingContext);

  if (!invoice) return null;

  return (
    <div className="InvoiceMoreInfo">
      <div className="InvoiceMoreInfo__info">
        <h2>{t('invoice.invoiceMoreInfo')}</h2>
        <div className="InvoiceMoreInfo__row">
          <span>{t('invoice.invoiceLocation')}</span>
          <span>{invoice.invoice_location}</span>
        </div>
        <div className="InvoiceMoreInfo__row">
          <span>{t('invoice.invoiceCreator')}</span>
          <span>{invoice.created_by}</span>
        </div>
      </div>
    </div>
  );
};

export default InvoiceMoreInfo;
