import { CodeState, CodeActionTypes, GET_CODE } from './code.types';

const initialState: CodeState = {
  code: []
};

export default (state = initialState, action: CodeActionTypes) => {
  switch (action.type) {
    case GET_CODE:
      return {
        ...state,
        code: [...action.payload] 
      };

    default:
      return state;
  }
};
