import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { throttle } from 'lodash';
import customerReducer from './customer/customer.reducer';
import bookingReducer from './booking/booking.reducer';
import codeReducer from './code/code.reducer';
import { CustomerState } from './customer/customer.types';
import { BookingState } from './booking/booking.types';
import { CodeState } from './code/code.types';
import { CountryState } from './country/country.types';
import countryReducer from './country/country.reducer';
import { SimulatorState } from './simulator/simulator.types';
import simulatorReducer from './simulator/simulator.reducer';

export interface AppState {
  customer: CustomerState;
  booking: BookingState;
  genderCodes: CodeState;
  countries: CountryState;
  loungeSimulators: SimulatorState
}

const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('lapState', serializedState);
  } catch (error) {
    console.warn(error);
  }
};

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
  customer: customerReducer,
  booking: bookingReducer,
  genderCodes: codeReducer,
  countries: countryReducer,
  loungeSimulators: simulatorReducer
});

let middleware = null;

const apiEnv = process.env.REACT_APP_ENV;

apiEnv === 'production'
  ? (middleware = applyMiddleware())
  : (middleware = composeEnhancers());

const store = createStore(reducers, middleware);

store.subscribe(
  throttle(() => {
    saveState({
      customer: store.getState().customer
    });
  }, 1000)
);

export default store;
