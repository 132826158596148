import React, { useContext, useRef, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import { ParsedEvent } from '../../../store/booking/booking.types';
import moreIcon from '../../../images/more.svg';
import { BookingContext } from '../../../context/booking.ctx';
import { ReactComponent as CustomersIcon } from '../../../images/customers.svg';
import { ReactComponent as DurationIcon } from '../../../images/duration.svg';
import noteIcon from '../../../images/Note.svg';

interface Props {
  event: ParsedEvent;
}

const GridItem = ({
  event: {
    id,
    name,
    simulators,
    time_duration,
    top,
    isAtBottom,
    left,
    width,
    height,
    internal,
    status,
    note,
    srp_internal
  }
}: Props) => {
  const [t] = useTranslation();
  const ref = useRef(null);
  // const customer = useWithSelection(customerSelector);
  const [active, setActive] = useState<boolean>(false);
  const { showDetails, showInternal } = useContext(BookingContext);
  const toggle = () => setActive(!active);
  const close = () => setActive(false);
  useOnClickOutside(ref, close);

  return (
    <div
      className={cx('GridItem', {
        internal,
        checkedin: status && (status === 'completed' || status === 'checked-in'),
        active
      })}
      style={{
        top: top,
        width: `${width}%`,
        height: isAtBottom ? 'auto' : `${height}rem`,
        left: `${left}%`,
        bottom: isAtBottom ? 0 : 'auto'
      }}
    >
      <div className={cx('Actions', { active })} ref={ref}>
        <span onClick={toggle}>
          <img src={moreIcon} alt="" />
        </span>
        {active && (
          <div>
            {!internal && (<span onClick={() => showDetails(id)}>
              {t('booking.viewDetails')}
            </span>)}
            {internal && (<span onClick={() => showInternal(id, srp_internal)}>
              {t('booking.viewDetails')}
            </span>)}
          </div>
        )}
      </div>
      <span className="GridItem__name">{name}</span>
      <span className="GridItem__data">
        <CustomersIcon /> {simulators >= 999 ? 'All Simulators' : simulators} <DurationIcon />
        {time_duration}
      </span>
      {note && <img src={noteIcon} alt="" />}
    </div>
  );
};

export default GridItem;
