import React, { useState, useContext, useEffect } from 'react';
import { InternalReservationDetails } from '../../store/booking/booking.types';
import { getInternalReservationDetails, getReservationDetails } from '../../store/booking/booking.actions';
import { format } from 'date-fns';
import { BookingContext } from '../../context/booking.ctx';
import './InternalReservationInfo.scss';
import { useTranslation } from 'react-i18next';

const InternalReservationInfo = () => {
  const [t] = useTranslation();
  const { internalReservation } = useContext(BookingContext);
  const { srpInternal } = useContext(BookingContext);
  const [booking, setBooking] = useState<null | InternalReservationDetails>(null);

  useEffect(() => {
    if (!internalReservation) return;
    if (srpInternal) {
      (async () => {
        try {
          const res = await getReservationDetails(internalReservation);
          res.description = res.note;
          setBooking(res);
        } catch (error) { }
      })();
    } else {
      (async () => {
        try {
          const res = await getInternalReservationDetails(internalReservation);
          setBooking(res);
        } catch (error) { }
      })();
    }
  }, [internalReservation, srpInternal]);

  if (!booking) return null;

  return (
    <div className="InternalReservationInfo">
      <div className="InternalReservationInfo__info">
        <h2>{t('booking.internalReservationInfo')}</h2>
        <div className="InternalReservationInfo__row">
          <span>{t('booking.time')}</span>
          <span>
            {format(new Date(booking.start_time), 'HH:mm')} -{' '}
            {format(new Date(booking.end_time), 'HH:mm')}
          </span>
        </div>
        <div className="InternalReservationInfo__row">
          <span>{t('booking.description')}</span>
          <span>{booking.description}</span>
        </div>
        <div className="InternalReservationInfo__row">
          <span>{t('booking.createdBy')}</span>
          <span>{booking.created_by}</span>
        </div>
      </div>
    </div>
  );
};

export default InternalReservationInfo;
