import { CountryActionTypes, GET_COUNTRY } from './country.types';
import i18n from '../../i18n/index';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';

const options = {
  headers: {
    'x-locale': i18n.language
  }
};

export const getCountries = async (
  dispatch: Dispatch<CountryActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/countries`, options);
    const countryData = res.data.data;

    dispatch({
      type: GET_COUNTRY,
      payload: countryData
    });
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};
