import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { IListField } from './FormField';

interface Select {
  name: string;
  i18nKey: string;
  value: string;
  errors?: any;
  errorMessage?: string;
  list?: IListField[];
  touched?: any;
  onChange?: any;
  setFieldValue: (name: string, value: string) => void;
  disabled?: boolean;
}

const Select = ({
  name,
  value,
  i18nKey,
  disabled,
  errorMessage,
  list,
  errors = {},
  touched = {},
  onChange = null,
  setFieldValue
}: Select) => {
  const [t] = useTranslation();
  const errorTrans = t(`${i18nKey}.${errors[name]}`);
  const errorMsg =
    errorTrans === `${i18nKey}.${errors[name]}` ? errors[name] : errorTrans;

    if (onChange === null){
      onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        setFieldValue(evt.target.name, evt.target.value);
      };
    }

  return (
    <div
      className={`FormField  ${value ? 'has-value' : ''} ${
        disabled ? 'is-disabled' : ''
      } ${name}`}
    >
      {i18nKey && <label>{t(`${i18nKey}.label`)}</label>}
      <div className="FormField-select">
        <select
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          <option value="" className="is-hidden" />
          {list &&
            list.map((o: IListField) => (
              <option value={o.id || o.value} key={o.id || o.value}>
                {o.label || o.name}
              </option>
            ))}
        </select>
        <span className="FormField-focusIndicator" />
        <span
          className={`FormField-selectIndicator ${value ? 'has-value' : ''}`}
        />
      </div>
      {((touched[name] && errors[name]) || errorMessage) && (
        <p className="FormField-error">
          {errorMessage ? errorMessage : errorMsg}
        </p>
      )}
    </div>
  );
};

export default Select;
