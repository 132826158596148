import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { SetStateType } from '../../../types/utils.types';

import './Option.scss';

interface Props {
  value: any;
  setValue: SetStateType<any>;
  callback: () => void;
  isSelected: boolean;
}

const Option: FunctionComponent<Props> = ({
  children,
  value,
  setValue,
  callback,
  isSelected
}) => {
  const handleClick = () => {
    setValue(value);
    callback();
  };
  return (
    <div className={cx(' Option', { isSelected })} onClick={handleClick}>
      {children}
      <span />
    </div>
  );
};

export default Option;
