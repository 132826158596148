import React, { useState, useContext, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import icon from '../../../images/arrow-link-back.svg';
import {
  InvoicedPlan,
  RidesHistory
} from '../../../store/customers/customers.types';

import './History.scss';
import { BookingContext } from '../../../context/booking.ctx';

interface Props {
  plans: InvoicedPlan[];
  rides: RidesHistory[];
}

const History: FunctionComponent<Props> = ({ plans, rides, children }) => {

  const [t] = useTranslation();
  const [active, setActive] = useState<number>(0);
  const { showInvoice } = useContext(BookingContext);
 
  return (
    <div className="History">
      <div className="History__header">
        <h3>{t('customers.history')}</h3>
        <div className="History__tabs">
          <span
            className={cx({ active: active === 0 })}
            onClick={() => setActive(0)}
          >
            {t('customers.rides')}
          </span>
          <span
            className={cx({ active: active === 1 })}
            onClick={() => setActive(1)}
          >
            {t('customers.subscriptions')}
          </span>
        </div>
      </div>
      <div className="History__body">
        <div className="History__table">
          {active === 0 ? (
            <>
              <div className="History__table-header History__table-row __rides">
                <div>{t('customers.invoiceDateTime')}</div>
                <div>{t('customers.rideProduct')}</div>
                <div>{t('customers.rideDateTime')}</div>
                <div>{t('customers.price')}</div>
              </div>
              {rides.reverse().map((r: RidesHistory) => (
                <div
                  className="History__table-body History__table-row __rides"
                  key={r.id}
                >
                  <div>
                    {' '}
                    {r.invoice_date && (
                      format(new Date(r.invoice_date), 'dd.MM.yyyy / HH:mm')
                    )}
                  </div>
                  <div>{r.product}</div>
                  <div>
                    {' '}
                    {format(new Date(r.start_time), 'dd.MM.yyyy / HH:mm')}
                  </div>
                  <div>
                    {r.currency} {r.price}
                    <Link
                      to={{
                        pathname: '/calendar',
                        state: {
                          id: r.id,
                          start_time: r.start_time,
                          rideDetails: true
                        }
                      }}
                    >
                      {t('customers.view')} <img src={icon} alt="" />
                    </Link>
                  </div>
                </div>
              ))}
              {children}
            </>
          ) : (
            <>
              <div className="History__table-header History__table-row __subs">
                <div>{t('customers.invoiceDateTime')}</div>
                <div>{t('customers.subscriptions')}</div>
                <div>{t('customers.invoices')}</div>
                <div>{t('customers.expires')}</div>
                <div>{t('customers.price')}</div>
              </div>
              {plans.map((p: InvoicedPlan) => (
                <div
                  key={p.invoice_date}
                  className="History__table-body History__table-row __subs"
                >
                  <div>
                    {format(new Date(p.invoice_date), 'dd.MM.yyyy / HH:mm')}
                  </div>
                  <div>{p.subscription}</div>
                  <div>{p.change_type}</div>
                  <div>
                    {p.expires_at
                      ? format(new Date(p.expires_at), 'dd.MM.yyyy / HH:mm')
                      : 'N/A'}
                  </div>
                  <div>
                    {p.price_unit} {p.price}
                    <span onClick={() => {showInvoice(p)}} className="MoreInfoBtn">
                      {t('customers.invoiceMoreInfo')}
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default History;
