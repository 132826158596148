import React, { useState } from 'react';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Langs from '../../components/Langs';
import logo from '../../images/racing-unleashed-mark.svg';
import useWithSelection from '../../hooks/useWithSelection';
import useWithDispatch from '../../hooks/useWithDispatch';
import { customersSelector, customerSelector } from '../../store/customer/customer.selectors';
import { removeCurrentCustomer } from '../../store/customer/customer.actions';
import { Customer } from '../../store/customer/customer.types';
import CustomerItem from './CustomerItem';
import AddAccount from './AddAccount';

import './ScreenLock.scss';

const ScreenLock = () => {
  const customers = useWithSelection(customersSelector);
  const currentCustomer = useWithSelection(customerSelector);
  const [t] = useTranslation();
  const [selected, setSelected] = useState<string | null>(null);
  const clearCustomer = useWithDispatch(removeCurrentCustomer);

  const clearSelected = () => setSelected(null);

  if (currentCustomer) return <Redirect to="/" />;

  return (
    <div className={cx('ScreenLock')}>
      <div className="ScreenLock__header">
        <img src={logo} alt="" />
        {selected && (
          <Button
            label={t('auth.switchAccount')}
            onClick={clearSelected}
            secondary
            inverted
          />
        )}
        {currentCustomer && (
          <Button
            label={currentCustomer.full_name}
            onClick={clearCustomer}
            secondary
            inverted
          />
        )}
      </div>
      {!selected && <h1>{t('auth.lockTitle')}</h1>}
      <div className="ScreenLock__customers">
        {customers.map((u: Customer) => {
          if (selected && selected !== u.id) return null;

          return (
            <CustomerItem
              key={u.id}
              customer={u}
              setSelected={setSelected}
              selected={selected === u.id}
            />
          );
        })}
        {!selected && <AddAccount />}
      </div>
      <Langs />
    </div>
  );
};

export default ScreenLock;
