import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import add from '../../../images/Add-white.svg';

import './AddAccount.scss';

const AddAccount = () => {
  const [t] = useTranslation();

  return (
    <div className={cx('AddAccount')}>
      <Link to="/login">
        <img src={add} alt="" />
      </Link>
      <span>{t('auth.addAccount')}</span>
    </div>
  );
};

export default AddAccount;
