import { CodeActionTypes, GET_CODE } from './code.types';
import i18n from '../../i18n/index';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';

const options = {
  headers: {
    'x-locale': i18n.language
  }
};

export const getGenderCodes = async (
  dispatch: Dispatch<CodeActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/codes/genders`, options);
    const codeData = res.data.data;

    dispatch({
      type: GET_CODE,
      payload: codeData
    });
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};
