import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import FormField from '../../components/FormField';
import CheckboxRadio from '../../components/FormField/CheckboxRadio';
import { toastUtil } from '../../utils/toast.utils';
import validationSchema from './validationSchema';
import { formModel, initialValues } from './formModel';
import Button from '../../components/Button';
import Langs from '../../components/Langs';
import useWithDispatch from '../../hooks/useWithDispatch';
import { login } from '../../store/customer/customer.actions';
import logo from '../../images/racing-unleashed-mark.svg';

import './Login.scss';

const Login = () => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const loginAction = useWithDispatch(login);
  const [t] = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        await loginAction(values);
        history.push('/');
      } catch (err) {
        setSubmitting(false);
        if (err.meta.message)
          formMethods.setFieldError('password', err.meta.message);
        if (err.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (err && err.meta) toastUtil('error', err.meta.message);
      }
    }
  });

  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <div className="Login">
      <div className="Login__header">
        <img src={logo} alt="RU" />
        <Link to="/switch-customers">
          <Button secondary inverted label={t('auth.loggedIn')} />
        </Link>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <h2>{t('auth.login.title')}</h2>
        <h4>{t('auth.login.subtitle')}</h4>
        {formModel.map((field) => (
          <FormField
            key={field.name}
            i18nKey={field.i18nKey}
            type={field.type}
            errors={formik.errors}
            touched={formik.touched}
            setFieldValue={formik.setFieldValue}
            {...formik.getFieldProps(field.name)}
          />
        ))}
        <CheckboxRadio
          onChange={formik.handleChange}
          name="remember_me"
          value={formik.values.remember_me}
          i18nKey="auth.login.rememberMe"
        />
        <div className="Login__actions">
          <Button
            type="submit"
            disabled={submitting || !isValidForm}
            submitting={submitting}
            label={t('auth.login.submit')}
          />
          <Link to="/forgot-password">{t('auth.login.c2a')}</Link>
        </div>
      </form>
      <Langs />
    </div>
  );
};

export default Login;
