import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import useWithSelection from '../../hooks/useWithSelection';
import dispatch from '../../hooks/useWithDispatch';
import { customerSelector } from '../../store/customer/customer.selectors';
import {
  logout,
  removeCurrentCustomer,
  updateCurrentCustomer
} from '../../store/customer/customer.actions';
import Button from '../Button';
import './Header.scss';

const langs = [
  {
    text: 'en',
    key: 'en'
  },
  {
    text: 'de',
    key: 'de'
  },
  {
    text: 'es',
    key: 'es'
  }
];

const CustomerMenu = () => {
  const currentCustomer = useWithSelection(customerSelector);
  const logoutAction = dispatch(logout);
  const removeCustomer = dispatch(removeCurrentCustomer);
  const updateCustomer = dispatch(updateCurrentCustomer);
  const ref = useRef(null);
  const [t, i18next] = useTranslation();
  const [activeDropdown, setActiveDropdown] = useState<boolean>(false);

  const toggleDropdown = () => setActiveDropdown(!activeDropdown);
  const closeDropdown = () => {
    if (activeDropdown) setActiveDropdown(false);
  };

  const changeLang = (lang: string): void => {
    updateCustomer({ preferred_language: lang });
    i18next.changeLanguage(lang);
    closeDropdown();
  };

  useOnClickOutside(ref, closeDropdown);

  return (
    <ul className="CustomerMenu">
      <li>
        <NavLink to="/" exact className="__home" activeClassName="active" />
      </li>
      <li>
        <NavLink
          to="/calendar"
          exact
          className="__calendar"
          activeClassName="active"
        />
      </li>
      <li ref={ref}>
        <span
          className={cx('__profile', { active: activeDropdown })}
          onClick={toggleDropdown}
        />
        {currentCustomer && (
          <div className={cx('CustomerMenu__dropdown', { active: activeDropdown })}>
            <div className="CustomerMenu__info">
              <div>
                <img src={currentCustomer?.profile_pic_url} alt="" />
              </div>
              <div>
                <span>{currentCustomer?.full_name}</span>
                {currentCustomer?.email}
              </div>
            </div>
            <div className="CustomerMenu__role">
              {t('header.role')}: <span>LOUNGE MANAGER</span>
            </div>
            <div className="CustomerMenu__links">
              <NavLink
                to="/profile"
                exact
                activeClassName="active"
                onClick={closeDropdown}
              >
                {t('header.myProfile')}
              </NavLink>
            </div>
            <div className="CustomerMenu__langs">
              <span>{t('header.language')}:</span>
              {langs.map((lang) => (
                <span
                  key={lang.key}
                  className={i18next.language === lang.key ? 'active' : ''}
                  onClick={() => changeLang(lang.key)}
                >
                  {lang.text}
                </span>
              ))}
            </div>
            <div className="CustomerMenu__actions">
              <Button
                label={t('header.signOut')}
                white
                small
                onClick={logoutAction}
              />
              <Button
                label={t('header.switchCustomer')}
                secondary
                inverted
                small
                onClick={removeCustomer}
              />
            </div>
          </div>
        )}
      </li>
    </ul>
  );
};

export default CustomerMenu;
