import auth from './auth';
import header from './header';
import profile from './profile';
import booking from './booking';
import calendar from './calendar';
import customers from './customers';
import invoice from './invoice';

const en = {
  auth,
  header,
  profile,
  calendar,
  booking,
  customers,
  invoice
};

export default en;
