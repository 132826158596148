import { BookingState, BookingActionTypes, GET_LOUNGE } from './booking.types';

const initialState: BookingState = {
  lounge: []
};

export default (state = initialState, action: BookingActionTypes) => {
  switch (action.type) {
    case GET_LOUNGE:
      return {
        ...state,
        lounge: [...action.payload]
      };

    default:
      return state;
  }
};
