import React, { useState, useEffect } from 'react';
import { isBefore } from 'date-fns';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';
import {
  getAvailableTimes,
  getAvailableDates
} from '../../store/booking/booking.actions';
import {
  AvailableDatesResponse,
  AvailableTimesState
} from '../../store/booking/booking.types';
import Picker from '../../components/Picker';
import Grid from './Grid';
import CalendarNav from './CalendarNav';
import Filters from './Filters';

import './Calendar.scss';

const Calendar = () => {
  const { default_lounge_id } = useWithSelection(customerSelector);
  const lounge_id = default_lounge_id;
  const [lounge, setLounge] = useState<string>(default_lounge_id);
  const [membership, setMembership] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [sims, setSims] = useState<number>(1);
  const [ridesSharing, setRidesSharing] = useState<boolean>(false);
  const [slot, setSlot] = useState<string | null>(null);
  const [date, setDate] = useState<Date>(new Date());
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [availableDates, setAvailableDates] = useState<
    AvailableDatesResponse[]
  >([]);
  const [availableTimes, setAvailableTimes] = useState<AvailableTimesState>({
    times: [],
    duration: 15,
    sims
  });

  const onDayClick = (day: any) => {
    setDate(day);
  };

  const fetchAvailableTime = async (duration?: number) => {
    try {
      const { available_slots } = await getAvailableTimes({
        selected_time: time,
        simulators: sims,
        date,
        lounge_id: lounge,
        time_slot_id: slot
      });
      setAvailableTimes({
        times: available_slots,
        duration: duration || 15,
        sims
      });
    } catch (error) {}
  };

  const selectSlotFromRow = (time: string) => {
    if (isBefore(new Date(time), new Date())) return;
    setAvailableTimes({
      times: [time],
      duration: 15,
      sims: 1
    });
  };

  useEffect(() => {
    setAvailableTimes({ duration: 15, times: [], sims });
    if (!slot) return;
    (async () => {
      try {
        const res = await getAvailableDates({
          selected_time: time,
          simulators: sims,
          date: selectedMonth,
          lounge_id,
          time_slot_id: slot
        });
        setAvailableDates(res);
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [lounge, slot, sims, time, selectedMonth]);

  useEffect(() => {
    setSelectedMonth(date);
  }, [date]);

  return (
    <div className="Calendar">
      <div className="Calendar__sidebar">
        <Filters
          lounge={lounge}
          setLounge={setLounge}
          membership={membership}
          setMembership={setMembership}
          time={time}
          setTime={setTime}
          sims={sims}
          setSims={setSims}
          ridesSharing={ridesSharing}
          setRidesSharing={setRidesSharing}
          slot={slot}
          setSlot={setSlot}
          onClick={fetchAvailableTime}
        />
        <Picker
          onDayClick={onDayClick}
          selected={date}
          setSelectedMonth={setSelectedMonth}
          availableDates={availableDates}
        />
      </div>
      <div className="Calendar__day">
        <CalendarNav date={date} setDate={setDate} />
        <Grid
          date={date}
          setDate={setDate}
          loungeId={lounge}
          availableTimes={availableTimes}
          initSims={sims}
          initRidesSharing={ridesSharing}
          initSlot={slot}
          onReservationSuccess={fetchAvailableTime}
          selectSlotFromRow={selectSlotFromRow}
        />
      </div>
    </div>
  );
};

export default Calendar;
