import { toast } from 'react-toastify';

export const toastUtil = (
  type: string = 'info',
  message: string,
  position: string = 'bottom-right',
  autoClose: number = 5000,
  hideProgressBar: boolean = false,
  closeOnClick: boolean = true,
  pauseOnHover: boolean = true,
  draggable: boolean = true,
  closeButton: boolean = true
) => {
  // @ts-ignore
  toast[type](message, {
    position,
    autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    closeButton
  });
};
