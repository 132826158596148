import axios, { AxiosInstance } from 'axios';
import store from '../store';
import { logout } from '../store/customer/customer.actions';

export const API_ROOT = process.env.REACT_APP_API;

const api: AxiosInstance = axios.create({
  baseURL: API_ROOT
});

api.interceptors.request.use(
  async (config) => {
    // set access_token
    // add access_token to the request headers
    const token = localStorage.getItem('lapToken');
    if (token) config.headers['x-auth-token'] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) logout(store.dispatch);
    return Promise.reject(error);
  }
);

export default api;
