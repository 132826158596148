import React, { ChangeEvent, FunctionComponent } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import './CheckboxRadio.scss';

interface CheckboxRadio {
  name: string;
  value: string;
  disabled?: boolean;
  i18nKey?: string;
  isRadio?: boolean;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxRadio: FunctionComponent<CheckboxRadio> = ({
  name,
  onChange,
  value,
  disabled,
  i18nKey,
  isRadio,
  children
}) => {
  const [t] = useTranslation();

  return (
    <div className="CheckboxRadio">
      <input
        name={name}
        id={`CheckboxRadio-${name}`}
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      <label htmlFor={`CheckboxRadio-${name}`}>
        <span className={cx('CheckboxRadio-box', { isRadio })} />
        {i18nKey && <span>{t(i18nKey)}</span>}
      </label>
      <span>{children}</span>
    </div>
  );
};

export default CheckboxRadio;
