const customers = {
  customerList: 'Customer List',
  newCustomer: 'New Customer',
  search: 'Search',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  mobile: 'Mobile',
  currentMembership: 'Current Membership',
  chooseLounge: 'Choose Lounge',
  allLounges: 'All Lounges',
  chooseCustomers: 'Choose Customers',
  show: 'Show',
  all: 'All',
  allCustomers: 'All Customers',
  rowsPerPage: 'Rows per page',
  of: 'of',
  createNewCustomer: 'Create new customer',
  customersProfile: 'Customers Profile',
  save: 'Save',
  notes: 'Notes',
  notesPlaceholder: 'Enter note here...',
  membershipDetails: 'Membership Details',
  open: 'Open',
  upgrade: 'Upgrade',
  renew: 'Renew',
  discounts: 'Discounts',
  upgradeMembership: 'Upgrade Membership',
  renewMembership: 'Renew Membership',
  choosePackage: 'Choose membership package.',
  package: 'Package',
  description: 'Description',
  membership: {
    label: 'Choose Subscription'
  },
  confirm: 'Confirm',
  history: 'History',
  rides: 'Rides',
  subscriptions: 'Subscriptions',
  invoiceDateTime: 'Invoice Date / Time',
  rideProduct: 'Ride Product',
  rideDateTime: 'Ride Date / Time',
  price: 'Price',
  view: 'View',
  invoices: 'Invoices',
  expires: 'Expires',
  makeBooking: 'Make Booking',
  invoiceMoreInfo: 'More'
};

export default customers;
