export const GET_LOUNGE = 'GET_LOUNGE';
export const GET_MEMBERSHIPS = 'GET_MEMBERSHIPS';

export interface Lounge {
  opens_at: string;
  closes_at: string;
  simulators_count: number;
  step: number;
  unavailable_slots: string[];
  opening_times: string[];
}

export interface Reservation {
  id: string;
  internal: boolean;
  name: string;
  start_time: string;
  end_time: string;
  simulators: number;
  time_duration: number;
  note: string | null;
  payment_method: string;
  srp_internal: boolean;
}

export interface Lounge {
  id: string;
  name: string;
  opens_at: string;
  closes_at: string;
  created_at: string;
  updated_at: string;
  available_simulators: number;
  max_group_size: number;
  lounge_owner: LoungeOwner;
  timezone: string;
}

interface LoungeOwner {
  country: string;
  currency: string;
  id: string;
  locale: string;
  name: string;
  vat: string;
}

export interface Membership {
  id: string;
  name: string;
  description: null | string;
}

export interface TimeSlot {
  id: string;
  name: string;
  description: null | string;
  duration?: string;
  duration_in_minutes: number;
}

export interface Event {
  id: string;
  internal: boolean;
  srp_internal: boolean;
  name: string;
  start_time: string;
  end_time: string;
  simulators: number;
  time_duration: number;
  reverseOrder?: boolean;
  status?: string;
  note: string | null;
  payment_method: string;
}

export interface EventDimension {
  top: number;
  width: number;
  height: number;
  left: number;
  isAtBottom?: boolean;
}

export interface ParsedFreeSlot extends EventDimension {
  time: string;
}

export interface ParsedEvent extends Event, EventDimension { }

export interface AvailableSlot {
  start_time: string;
  simulators: number;
  time_duration: number;
  freeSlot: boolean;
}

export interface ParseAvailableSLot extends EventDimension, AvailableSlot { }

export interface AvailableTimesResponse extends Lounge {
  available_slots: string[];
}

export interface AvailableDatesResponse {
  date: string;
  status: string;
  color: string;
}

export interface GetAvailableTimesDatesParams {
  date: Date;
  lounge_id: string;
  time_slot_id: string | null;
  simulators?: number;
  selected_time?: string;
}

export interface GetReservationsResponse extends Lounge {
  reservations: Reservation[];
}

export interface BookingState {
  lounge: Lounge[] | [];
}

interface GetLoungeAction {
  type: typeof GET_LOUNGE;
  payload: Lounge[];
}

export type BookingActionTypes = GetLoungeAction;

export interface ReservationDetailsCustomer {
  id: string;
  first_name: string;
  last_name: string;
  nickname: string;
  email: string;
  address1: null | string;
  active: boolean;
  preferred_language: string;
  dob: string;
  phone_number: string | null;
  activated_at: string;
  registered_at: string;
  full_name: string;
  profile_pic_url: string;
  unconfirmed_email: any;
  lounge_id: string;
  status?: string;
  roles: string[];
}

interface ReservationDetailsTimeSlot extends TimeSlot {
  price: string;
  currency: string;
  available: number;
}

interface ReservationDetailsBase {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  bi_data_id: number;
  timezone: string;
  selectable: boolean;
  lounge_owner_id: string;
}

interface ReservationDetailsInvoice {
  id: string;
  status: string;
  paid_by_id: string;
  total: string;
  currency: string;
  created_at: string;
  updated_at: string;
}

export interface Coupon {
  name: string;
  description: string;
  discount_label: string;
}

export interface ReservationDetails {
  id: string;
  date: string;
  start_time: string;
  end_time: string;
  number_of_simulators: number;
  duration_in_minutes: number;
  status: string;
  customer: ReservationDetailsCustomer;
  time_slot: ReservationDetailsTimeSlot;
  lounge: ReservationDetailsBase;
  invoice: ReservationDetailsInvoice;
  note: string | null;
  payment_method: string;
  created_by: string;
  rides_sharing: boolean;
  shared_rides: number;
  group_ride_customers: ReservationDetailsCustomer[];
  coupon_applicable: boolean;
  coupon: Coupon | null;
  description: string | null;
}

export interface InternalReservationDetails {
  id: string;
  start_time: string;
  end_time: string;
  description: string | null;
  created_by: string;
}

export interface GetSummaryArgs {
  start_time: string;
  time_slot_id: string;
  simulators: number;
  lounge_id: string;
  customer_id: string | null;
  rides_sharing: boolean;
}

export interface SummaryDiscount {
  name: string;
  value: number;
  type: string;
  label: string;
  price_effect: string;
}

export interface Product {
  base_price: number;
  final_price: number;
  quantity: number;
  discounts: SummaryDiscount[];
}

export interface SummaryType {
  currency: string;
  base_price_sum: number;
  final_price_sum: number;
  products: Product[];
}

export interface AvailableTimesState {
  times: string[];
  duration: number;
  sims: number;
}

export interface CreateReservationArgs {
  time: string;
  sims: number;
  lounge: string;
  customerId: string | null;
  slot: string | null;
  isNewCustomer: boolean;
  first_name: string;
  last_name: string;
  email: string;
  lang: string;
  rides_sharing: boolean;
}
