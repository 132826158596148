import axios from 'axios';
import { Dispatch } from 'react';

import {
  LOGOUT,
  LOGIN,
  CHANGE_LANG,
  UPDATE_CURRENT_MEMBER,
  CustomerActionTypes,
  Customer,
  SET_CURRENT_MEMBER,
  REMOVE_CURRENT_MEMBER
} from './customer.types';
import i18n from '../../i18n/index';
import api, { API_ROOT } from '../../config/api.config';

const options = {
  headers: {
    'x-locale': i18n.language
  }
};

export const changeLang = (lang: string) => ({
  type: CHANGE_LANG,
  payload: lang
});

export const logout = (dispatch: Dispatch<CustomerActionTypes>): void => {
  localStorage.removeItem('lapToken');
  i18n.changeLanguage(
    navigator.language.toLowerCase().match('en') ? 'en' : 'de'
  );

  dispatch({
    type: LOGOUT
  });
};

export const login = async (
  dispatch: Dispatch<CustomerActionTypes>,
  customer: Customer
): Promise<any> => {
  try {
    const {
      data: { data }
    } = await axios.post(`${API_ROOT}/authentication`, customer, options);
    const customerData = { ...data.customer, token: data.token };

    localStorage.setItem('lapToken', customerData.token);
    dispatch({
      type: LOGIN,
      payload: customerData
    });
    i18n.changeLanguage(customerData.preferred_language);
    return customerData;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getCustomer = async (
  dispatch: Dispatch<CustomerActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/customers/me`);
    const customerData = res.data.data;

    dispatch({
      type: UPDATE_CURRENT_MEMBER,
      payload: customerData
    });
    i18n.changeLanguage(customerData.preferred_language);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateCurrentCustomer = async (
  dispatch: Dispatch<CustomerActionTypes>,
  data: any
): Promise<any> => {
  try {
    const res = await api.patch(`${API_ROOT}/customers`, data);
    const customerData = res.data;

    dispatch({
      type: UPDATE_CURRENT_MEMBER,
      payload: customerData.data
    });
    i18n.changeLanguage(customerData.preferred_language);
    return res.data;
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const removeCurrentCustomer = (dispatch: Dispatch<CustomerActionTypes>) => {
  localStorage.removeItem('lapToken');
  dispatch({
    type: REMOVE_CURRENT_MEMBER
  });
};

export const setCurrentCustomer = (
  dispatch: Dispatch<CustomerActionTypes>,
  customer: Customer
) => {
  localStorage.setItem('lapToken', customer.token);
  dispatch({
    type: SET_CURRENT_MEMBER,
    payload: customer
  });
};

export const verifyPin = async (
  dispatch: Dispatch<CustomerActionTypes>,
  customer: Customer,
  pin_code: string
) => {
  try {
    await axios.post(
      `${API_ROOT}/lap/pin_codes/verify`,
      {
        pin_code
      },
      {
        headers: {
          'x-auth-token': customer.token
        }
      }
    );
    dispatch({
      type: SET_CURRENT_MEMBER,
      payload: customer
    });
    localStorage.setItem('lapToken', customer.token);
    i18n.changeLanguage(customer.preferred_language);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updatePin = async (
  password: string,
  pin_code: string
): Promise<any> => {
  try {
    const res = await api.patch('/lap/pin_codes', {
      password,
      pin_code
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

// export const getCustomer = async (
//   dispatch: Dispatch<CustomerActionTypes>
// ): Promise<any> => {
//   try {
//     const res = await api.get(`${API_ROOT}/customers/me`);
//     const customerData = res.data.data;

//     dispatch({
//       type: SET_MEMBER,
//       payload: customerData
//     });
//     i18n.changeLanguage(customerData.preferred_language);
//   } catch (error) {
//     return Promise.reject(error.response.data);
//   }
// };
