import React, { useState, useContext, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { checkIn } from '../../store/booking/booking.actions';
import SearchField from '../SearchField/CheckinSearchField';
import { BookingContext } from '../../context/booking.ctx';
import { CustomerType } from '../../store/customers/customers.types';
import { createCustomer } from '../../store/customers/customers.actions';
import { customerSelector } from '../../store/customer/customer.selectors';
import { SetStateType } from '../../types/utils.types';
import useWithSelection from '../../hooks/useWithSelection';
import TextInput from '../../components/FormField/TextInput';
import clearIcon from '../../images/cancel.svg';
import { toastUtil } from '../../utils/toast.utils';
import { ReservationDetails } from '../../store/booking/booking.types';
import { ReactComponent as Icon } from '../../images/customer.svg';
import '../../views/Calendar/NewCustomer/NewCustomer.scss';

interface Props {
  booking: ReservationDetails | null;
  setPage: SetStateType<number>;
  setBooking: SetStateType<null | ReservationDetails>;
}

const inputFields = [
  {
    name: 'first_name',
    i18nKey: 'profile.first_name',
    type: 'text'
  },
  {
    name: 'last_name',
    i18nKey: 'profile.last_name',
    type: 'text'
  },
  {
    name: 'email',
    i18nKey: 'profile.email',
    type: 'text'
  }
];

const GroupCheckin = ({ booking, setBooking, setPage }: Props) => {
  const [t] = useTranslation();
  const { preferred_language } = useWithSelection(customerSelector);
  const { setCheckedInId } = useContext(BookingContext);
  const [active, setActive] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);
  const [customers, setCustomers] = useState<any>([]);
  const [newCustomer, setNewCustomer] = useState<any>({
    first_name: '',
    last_name: '',
    email: ''
  });

  const canAddCustomer: () => boolean = () => {
    if (booking && customers.length === booking.number_of_simulators - 1) {
      toastUtil(
        'error',
        `${booking.number_of_simulators}/${booking.number_of_simulators} ${t(
          'booking.addCheckingCustomerError'
        )}`
      );
      return false;
    }
    return true;
  };

  const add = (customer: CustomerType) => {
    if (canAddCustomer()) setCustomers([...customers, customer]);
  };

  const remove = (id: string) => {
    setCustomers(customers.filter((m: any) => m.id !== id));
  };

  const showCustomerForm = () => {
    if (canAddCustomer()) setActive(true);
  };

  const clear = () => {
    setActive(false);
    setNewCustomer({
      first_name: '',
      last_name: '',
      email: ''
    });
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  };

  const addNewCustomer = async () => {
    try {
      setCreating(true);
      const res = await createCustomer(
        newCustomer.first_name,
        newCustomer.last_name,
        newCustomer.email,
        booking!.lounge.id,
        preferred_language,
        false
      );

      setCustomers([...customers, res]);
      clear();
      setCreating(false);
    } catch (error) {
      setCreating(false);
    }
  };

  const handleCheckIn = async () => {
    try {
      setSubmitting(true);
      var ids: any[] = [];
      customers.forEach(function (m: any) {
        ids.push({ customer_id: m.id })
      });
      const res = await checkIn(booking!.id, ids);
      setBooking(res);
      setPage(0);
      setCheckedInId(booking!.id);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <div className="GroupCheckin">
      <h2>{t('booking.addOtherCustomers')}</h2>
      <h4>{t('booking.otherGroupCustomers')}</h4>
      <SearchField add={add} />
      <div className="NewCustomer">
        <div className="NewCustomer__header">
          <span onClick={showCustomerForm}>{t('booking.addNewCustomer')}</span>
          {active && <img src={clearIcon} alt="" onClick={clear} />}
        </div>
        {active && (
          <>
            <div className="NewCustomer__fields">
              {inputFields.map((f) => (
                <TextInput
                  key={f.name}
                  name={f.name}
                  i18nKey={f.i18nKey}
                  type="text"
                  value={newCustomer[f.name]}
                  onChange={handleChange}
                />
              ))}
            </div>
            <Button
              small
              secondary
              label={t('booking.addNewCustomer')}
              submitting={creating}
              disabled={
                !newCustomer.first_name ||
                !newCustomer.last_name ||
                !newCustomer.email
              }
              onClick={addNewCustomer}
            />
          </>
        )}
      </div>
      {!active && (
        <>
          <div className="GroupCheckin__customers">
            <span>{t('booking.bookedBy')}</span>
            <div>
              <Icon />{' '}
              {booking && `${booking.customer.full_name} (${booking.customer.email})`}
            </div>
            {customers.length > 0 && (
              <>
                <span>{t('booking.otherCustomers')}</span>
                {customers.map((m: any) => (
                  <div key={m.id}>
                    <Icon />
                    {m.first_name} {m.last_name} ({m.email})
                    <img
                      src={clearIcon}
                      alt=""
                      onClick={() => {
                        remove(m.id);
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <Button
            label={t('booking.done')}
            submitting={submitting}
            onClick={handleCheckIn}
          />
        </>
      )}
    </div>
  );
};

export default GroupCheckin;
