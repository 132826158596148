import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CloseDialog from '../../../components/CloseDialog';
import { SetStateType } from '../../../types/utils.types';
import { Membership } from '../../../store/booking/booking.types';
import { getMemberships } from '../../../store/booking/booking.actions';
import Option from '../Option';

interface Props {
  base: string | null;
  membership: string | null;
  setMembership: SetStateType<string | null>;
}

const MembershipFilter = ({ base, membership, setMembership }: Props) => {
  const [t] = useTranslation();
  const [memberships, setMemberships] = useState<Membership[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const selected = memberships.find((m: Membership) => m.id === membership);

  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await getMemberships(base);
        setMemberships(data);
        const filtered = data.find((m: Membership) => m.id === membership);
        if (!filtered) setMembership(null);
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [base]);

  return (
    <>
      <span
        className={cx('Customers__pill', { active: !!membership })}
        onClick={show}
      >
        {!membership && t('customers.allCustomers')}
        {membership && selected && selected.name}
      </span>
      <Dialog onClose={hide} open={open} className="ru-dialog">
        <CloseDialog onClick={hide} />
        <div className="Customers__filter-options">
          <h2>{t('customers.chooseCustomers')}</h2>
          <Option
            value={null}
            setValue={setMembership}
            callback={hide}
            isSelected={!membership}
          >
            {t('customers.allCustomers')}
          </Option>
          {memberships.map((m: Membership) => (
            <Option
              setValue={setMembership}
              value={m.id}
              callback={hide}
              isSelected={m.id === membership}
              key={m.id}
            >
              {m.name}
            </Option>
          ))}
        </div>
      </Dialog>
    </>
  );
};

export default MembershipFilter;
