import api, { API_ROOT } from '../../config/api.config';
import axios from 'axios';
import { toastUtil } from '../../utils/toast.utils';
import {
  CustomersArgs,
  Filters,
  CustomersResponse,
  Plan,
  Discount,
  InvoicedPlan,
  CustomerHistory,
  CustomerDetails
} from './customers.types';
import { FormikValues } from 'formik';

window.runningRequests = window.runningRequests || [];
var source = axios.CancelToken.source();

export const getCustomers = async ({
  search_term,
  default_lounge_id,
  membership_id,
  filter_with,
  size,
  page
}: CustomersArgs): Promise<CustomersResponse> => {
  try {
    let q = ``;
    if (page) q += `page=${page}&`;
    if (size) q += `size=${size}&`;
    if (search_term) q += `search_term=${encodeURI(search_term)}&`;
    if (default_lounge_id) q += `default_lounge_id=${default_lounge_id}&`;
    if (membership_id) q += `membership_id=${membership_id}&`;
    if (filter_with) q += `filter_with=${filter_with}`;

    if (window.runningRequests.indexOf("getCustomers") !== -1) {
      source.cancel('Operation canceled by the user.');
      source = axios.CancelToken.source();
    }
    window.runningRequests.push("getCustomers");

    const {
      data: { data, meta }
    } = await api.get(`${API_ROOT}/lap/customers?${q}`, {
      cancelToken: source.token
    });

    window.runningRequests.pop("getCustomers");
    return { data, meta };
  } catch (error) {
    window.runningRequests.pop("getCustomers");
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getCustomerFilters = async (): Promise<Filters> => {
  try {
    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/customers/filters`);

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const createCustomer = async (
  first_name: string,
  last_name: string,
  email: string,
  default_lounge_id: number | string,
  preferred_language: string,
  async: boolean
): Promise<any> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.post(`${API_ROOT}/lap/customers`, {
      first_name,
      last_name,
      email,
      default_lounge_id,
      preferred_language,
      silent_member: true,
      nickname: '',
      async
    });

    if (async) toastUtil('success', message);

    return data;
  } catch (error) {
    let msg = '';
    if (error.response.data.meta?.message)
      msg = error.response.data.meta.message;
    if (error.response.data['email']) {
      msg = `Email ${error.response.data.email[0]}`;
    }
    if (error.response.data['nickname']) {
      msg = `Nickname ${error.response.data.nickname[0]}`;
    }

    toastUtil('error', msg);
    return Promise.reject(error.response.data);
  }
};

export const getMembershipsForCustomer = async (id: string): Promise<Plan[]> => {
  try {
    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/customers/${id}/memberships/upgradable`);
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const upgradeMembership = async (
  customerId: string,
  planId: string
): Promise<Plan> => {
  try {
    const {
      data: {
        data: { membership_plan },
        meta: { message }
      }
    } = await api.put(
      `${API_ROOT}/lap/customers/${customerId}/memberships/${planId}/upgrade`
    );
    toastUtil('success', message);

    return membership_plan;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const renewMembership = async (customerId: string): Promise<Plan> => {
  try {
    const {
      data: {
        data: { membership_plan },
        meta: { message }
      }
    } = await api.put(`${API_ROOT}/lap/customers/${customerId}/membership/renew`);
    toastUtil('success', message);

    return membership_plan;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getQuotas = async (id: number): Promise<Discount[]> => {
  try {
    const {
      data: { data }
    } = await api.get(
      `${API_ROOT}/lap/customers/quotas?customer_id=${id}&plan_type=membership`
    );
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getInvoicedPlans = async (
  id: string
): Promise<InvoicedPlan[]> => {
  try {
    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/customers/${id}/memberships`);
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getCustomerReservations = async (
  id: string,
  page: number,
  size: number
): Promise<CustomerHistory> => {
  try {
    const {
      data: { data, meta }
    } = await api.get(
      `${API_ROOT}/lap/customers/${id}/reservations?page=${page}&size=${size}`
    );
    return { data, meta };
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getNote = async (id: string): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/customers/${id}/note`);
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const postNote = async (id: string, content: string): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api.post(`${API_ROOT}/lap/customers/${id}/notes`, { content });
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getCustomer = async (id: string): Promise<CustomerDetails> => {
  try {
    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/customers/${id}`);
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const updateCustomer = async (
  id: string,
  data: FormikValues | any
): Promise<any> => {
  try {
    await api.patch(`${API_ROOT}/lap/customers/${id}`, data);
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const activateCustomer = async (customer_id: string): Promise<any> => {
  try {
    const {
      data: {
        meta: { message }
      }
    } = await api.post(`${API_ROOT}/lap/customers/resend_token/`, {
      customer_id
    });
    toastUtil('success', message);
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};


export const activateAndResetCustomer = async (customer_id: string): Promise<any> => {
  try {
    const {
      data: {
        meta: { message }
      }
    } = await api.post(`${API_ROOT}/lap/customers/activate_and_reset_password/`, {
      customer_id
    });
    toastUtil('success', message);
    return true;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};
