import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { subDays, isAfter, isBefore, format } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import CloseDialog from '../../../components/CloseDialog';
import Button from '../../../components/Button';
import img from '../../../images/ru-symbol-blue.svg';
import MembershipForm from './MembershipForm';
import { Plan, Discount } from '../../../store/customers/customers.types';
import { getQuotas } from '../../../store/customers/customers.actions';

import './MembershipDetails.scss';

const unlimitedArray: Array<string | number> = [
  'unlimited',
  'unbegrenzt',
  'ilimitado'
];

interface Props {
  plan?: Plan;
  onUpgradeCallback: () => void;
}

interface Params {
  id: string;
}

const MembershipDetails = ({ plan, onUpgradeCallback }: Props) => {
  const { id } = useParams<Params>();
  const [t] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [renew, setRenew] = useState<boolean>(false);
  const [customerPlan, setCustomerPlan] = useState<Plan | undefined>(plan);
  const [discounts, setDiscounts] = useState<Discount[]>([]);

  const onOpenUpgrade = () => {
    setRenew(false);
    setOpen(true);
  };
  const onOpenRenew = () => {
    setRenew(true);
    setOpen(true);
  };
  const onClose = () => setOpen(false);
  const onUpgradeSucces = (newPlan: Plan) => {
    setCustomerPlan(newPlan);
    onUpgradeCallback();
    onClose();
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await getQuotas(id as unknown as number);
        setDiscounts(data);
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [customerPlan]);

  useEffect(() => {
    setCustomerPlan(plan);
  }, [plan]);

  let disabledRenew = true;

  if (customerPlan && customerPlan.expires_at) {
    const expiration = new Date(customerPlan.expires_at);
    const renewalStart = subDays(
      expiration,
      customerPlan.min_renewal_days as unknown as number
    );
    const today = new Date();
    disabledRenew =
      isBefore(today, expiration) && isAfter(today, renewalStart)
        ? false
        : true;
  }

  return (
    <>
      <div className="MembershipDetails">
        <div className="MembershipDetails__header">
          <h3>{t('customers.membershipDetails')}</h3>
          <div>
            <Button
              label={t('customers.renew')}
              small
              secondary
              onClick={onOpenRenew}
              disabled={disabledRenew}
            />
            <Button
              label={t('customers.upgrade')}
              small
              onClick={onOpenUpgrade}
              disabled={!customerPlan?.upgradeable}
            />
          </div>
        </div>
        <div className="MembershipDetails__body">
          <div className="MembershipDetails__name">
            <span>
              <img src={img} alt="" /> {customerPlan && customerPlan.name}
            </span>
            <span>
              {customerPlan && `${customerPlan.currency} ${customerPlan.paid_price}`}
            </span>
          </div>
          <div className="MembershipDetails__discounts">
            <div>{t('customers.discounts')}</div>
            {discounts.map((d: Discount) => {
              const hasIntervalStartEnd = Boolean(
                d.limit.customer_limit.interval_start &&
                  d.limit.customer_limit.interval_end
              );

              return (
                <React.Fragment key={d.discount_rule_name}>
                  <div>
                    <span>{d.pricing.description}</span>
                    <span>{d.pricing.label}</span>
                  </div>
                  {d.limit.customer_limit.show && (
                    <div>
                      <span>{d.limit.customer_limit.description}</span>
                      <span>
                        {hasIntervalStartEnd && (
                          <span>
                            {format(
                              new Date(d.limit.customer_limit.interval_start!),
                              'dd.MM.yyyy'
                            )}{' '}
                            -{' '}
                            {format(
                              new Date(d.limit.customer_limit.interval_end!),
                              'dd.MM.yyyy'
                            )}{' '}
                          </span>
                        )}
                        {hasIntervalStartEnd && '('}
                        {!unlimitedArray.includes(d.limit.customer_limit.total)
                          ? `${d.limit.customer_limit.used}
                              /${d.limit.customer_limit.total}`
                          : d.limit.customer_limit.total}
                        {hasIntervalStartEnd && ')'}
                      </span>
                    </div>
                  )}
                  {d.limit.quantity_limit.show && (
                    <div>
                      <span>{d.limit.customer_limit.description}</span>

                      <span>
                        {!unlimitedArray.includes(d.limit.quantity_limit.total)
                          ? `${d.limit.quantity_limit.remaining}
                              /${d.limit.quantity_limit.total}`
                          : d.limit.quantity_limit.total}
                      </span>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      {customerPlan && (
        <Dialog onClose={onClose} open={open}>
          <CloseDialog onClick={onClose} />
          <MembershipForm
            onSuccess={onUpgradeSucces}
            curentPlan={customerPlan}
            renew={renew}
          />
        </Dialog>
      )}
    </>
  );
};

export default MembershipDetails;
