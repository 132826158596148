import React from 'react';
import Select from '../../../components/FormField/Select';
import { Plan } from '../../../store/customers/customers.types';
import { SetStateType } from '../../../types/utils.types';

interface PlansDropdown {
  selected: Plan | null;
  setSelected: SetStateType<Plan | null>;
  plans: Plan[];
}

const PlansDropdown = React.memo(
  ({ selected, setSelected, plans }: PlansDropdown) => {
    const setFieldValue = (name: string, value: string) => {
      const plan: Plan | undefined = plans.find((p: Plan) => p.id === value);
      if (plan) setSelected(plan);
    };

    return (
      <div className="PlansDropdown">
        <Select
          i18nKey="customers.membership"
          name="base-center"
          value={selected ? selected.id + '' : ''}
          list={plans}
          setFieldValue={setFieldValue}
        />
      </div>
    );
  }
);

export default PlansDropdown;
