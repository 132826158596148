import React, { useState, useRef } from 'react';
import cx from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { SetStateType } from '../../../types/utils.types';
import { timeFromIsoDate } from '../../../utils/time.utils';

import './DropdownField.scss';

interface Props {
  label: string;
  setValue: SetStateType<any>;
  value: any;
  list: string[];
}

const DropdownField = ({ label, value, setValue, list }: Props) => {
  const ref = useRef(null);
  const [active, setActive] = useState<boolean>(false);

  const toggleDropdown = () => setActive(!active);
  const closeDropdown = () => {
    if (active) setActive(false);
  };
  const handleChange = (val: any) => {
    closeDropdown();
    setValue(val);
  };

  useOnClickOutside(ref, closeDropdown);

  return (
    <div className={cx('DropdownField')} ref={ref}>
      <span className={cx('DropdownField__label', { value })}>{label}</span>
      <div
        className={cx('DropdownField__value', { active })}
        onClick={toggleDropdown}
      >
        {timeFromIsoDate(value)}
        <span className={cx('DropdownField__arrow', { active })} />
      </div>
      {active && (
        <div className="DropdownField__options">
          {list.map((o: any) => (
            <span
              key={o}
              className={cx({
                selected: o.split('T')[1] === value.split('T')[1]
              })}
              onClick={() => handleChange(o)}
            >
              {timeFromIsoDate(o)}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownField;
