import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SetStateType } from '../../../types/utils.types';
import clearIcon from '../../../images/cancel.svg';
import TextInput from '../../../components/FormField/TextInput';

import './NewCustomer.scss';

interface Props {
  active: boolean;
  setActive: SetStateType<boolean>;
  newCustomer: any;
  setNewCustomer: SetStateType<any>;
}

const inputFields = [
  {
    name: 'first_name',
    i18nKey: 'profile.first_name',
    type: 'text'
  },
  {
    name: 'last_name',
    i18nKey: 'profile.last_name',
    type: 'text'
  },
  {
    name: 'email',
    i18nKey: 'profile.email',
    type: 'text'
  }
];

const NewCustomer = ({ active, setActive, newCustomer, setNewCustomer }: Props) => {
  const [t] = useTranslation();

  const clear = () => {
    setActive(false);
    setNewCustomer({
      first_name: '',
      last_name: '',
      email: ''
    });
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  };

  return (
    <div className="NewCustomer">
      <div className="NewCustomer__header">
        <span onClick={() => setActive(true)}>{t('booking.addNewCustomer')}</span>
        {active && <img src={clearIcon} alt="" onClick={clear} />}
      </div>
      {active && (
        <div className="NewCustomer__fields">
          {inputFields.map((f) => (
            <TextInput
              key={f.name}
              name={f.name}
              i18nKey={f.i18nKey}
              type="text"
              value={newCustomer[f.name]}
              onChange={handleChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NewCustomer;
