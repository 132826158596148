const profile = {
  myProfile: 'My Profile',
  identifier: {
    label: 'Email',
    required: 'This field is required'
  },
  nickname: {
    label: 'Username*',
    required: 'This field is required'
  },
  email: {
    label: 'Email*',
    required: 'This field is required',
    invalidEmail: 'Invalid email'
  },
  first_name: {
    label: 'First Name*',
    required: 'This field is required'
  },
  last_name: {
    label: 'Last Name*',
    required: 'This field is required'
  },
  default_lounge_id: {
    label: 'Default Lounge*',
    required: 'This field is required'
  },
  phone_number: {
    label: 'Phone Number*'
  },
  address1: {
    label: 'Address*',
    required: 'This field is required'
  },
  address2: {
    label: 'Address line 2',
    required: 'This field is required'
  },
  country_id: {
    label: 'Country*',
    required: 'This field is required'
  },
  city: {
    label: 'City*',
    required: 'This field is required'
  },
  zip_code: {
    label: 'Zip Code'
  },
  pob_country_id: {
    label: 'Country of Birth*',
    required: 'This field is required'
  },
  fav_lounge_id: {
    label: 'Favourite Lounge'
  },
  fav_simulator_id: {
    label: 'Favourite Simulator'
  },
  body_weight: {
    label: 'Body Weight (kg)'
  },
  body_height: {
    label: 'Body Height (cm)'
  },
  company: {
    label: 'Company Name'
  },
  gender_id: {
    label: 'Gender'
  },
  remember_me: {
    label: 'Remember Me'
  },
  tos: {
    label:
      'I accept the <1> Terms of Service </1> and <3> Privacy policy and use of cookies</3>'
  },
  lounge_id: {
    label: 'Lounge'
  },
  dob: {
    label: 'Date of birth'
  },
  preferred_language: {
    label: 'Language'
  },
  avatarChange: 'Change',
  submit: 'Update Profile',
  changePassTitle: 'Change Password',
  changePassText: 'Change your current password of your account.',
  changePinTitle: 'Change PIN Code',
  changePinText:
    'Change your PIN code which lets you enter your account more efficiently.',
  password: {
    label: 'Password',
    required: 'This field is required',
    minLength: 'Password should be at least 8 characters'
  },
  password_confirmation: {
    label: 'Confirm Password',
    required: 'This field is required',
    minLength: 'Password should be at least 8 characters',
    matching: 'Passwords do not match'
  },
  pin_code: {
    label: 'PIN',
    required: 'This field is required'
  },
  updatePassword: 'Update Password',
  updatePin: 'Update PIN'
};

export default profile;
