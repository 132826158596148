import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';

import './CustomersHeader.scss';

interface Props {
  numOfCustomers: number;
  openForm: () => void;
}

const CustomerList = ({ numOfCustomers, openForm }: Props) => {
  const [t] = useTranslation();
  return (
    <div className="CustomersHeader">
      <div className="CustomersHeader__title">
        <h2>{t('customers.customerList')}</h2>
        <span>({numOfCustomers})</span>
      </div>
      <Button label={t('customers.newCustomer')} onClick={openForm} />
    </div>
  );
};

export default CustomerList;
