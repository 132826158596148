const calendar = {
  filter: 'Filter',
  showSlots: 'Show Available Slots',
  sims: 'Simulators',
  lounge: 'Lounge',
  showFor: 'Show for',
  time: 'Time',
  durationInMin: 'Duration (in minutes)',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  today: 'Today',
  ridesSharing: 'Compartir Paquetes',
  ridesSharingDisabled: 'Deshabilitado',
  sharedRides: 'Viajes Compartidos',
};

export default calendar;
