import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SetStateType } from '../../types/utils.types';
import { ReactComponent as Icon } from '../../images/arrow-down-gray.svg';

interface Props {
  size: number;
  setSize: SetStateType<number>;
}

const sizes = [8, 12, 16, 24];

const Size = ({ size, setSize }: Props) => {
  const [t] = useTranslation();

  const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    setSize(Number(evt.target.value));
  };

  return (
    <div className="Size">
      {t('customers.rowsPerPage')}
      <div className="Size__select">
        <select value={size} onChange={handleChange}>
          {sizes.map((s: number) => (
            <option value={s} key={s}>
              {s}
            </option>
          ))}
        </select>
        <Icon />
      </div>
    </div>
  );
};

export default Size;
