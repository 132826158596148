import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import { toastUtil } from '../../../utils/toast.utils';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button';
import { updatePin } from '../../../store/customer/customer.actions';
import { formModel, initialValues } from './formModel';
import validationSchema from './validationSchema';

interface Props {
  close: () => void;
}

const PassForm = ({ close }: Props) => {
  const [t] = useTranslation();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        const response = await updatePin(values.password, values.pin_code);
        setSubmitting(false);
        console.log(response);
        toastUtil('success', response.meta.message);
        close();
      } catch (err) {
        setSubmitting(false);
        if (err.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (err && err.meta) toastUtil('error', err.meta.message);
      }
    }
  });

  const isValidForm = validationSchema.isValidSync({ ...formik.values });
  return (
    <div className="PassPinForm">
      <h2>{t('profile.changePinTitle')}</h2>
      <form onSubmit={formik.handleSubmit}>
        {formModel.map((field) => (
          <FormField
            key={field.name}
            i18nKey={field.i18nKey}
            type={field.type}
            errors={formik.errors}
            touched={formik.touched}
            setFieldValue={formik.setFieldValue}
            {...formik.getFieldProps(field.name)}
          />
        ))}
        <Button
          type="submit"
          disabled={submitting || !isValidForm}
          submitting={submitting}
          label={t('profile.updatePin')}
        />
      </form>
    </div>
  );
};

export default PassForm;
