import React, { useEffect, useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { format, addMinutes } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';
import useWithSelection from '../../../hooks/useWithSelection';
import { loungeSelector } from '../../../store/booking/booking.selectors';
import { customerSelector } from '../../../store/customer/customer.selectors';
import {
  getTimeSlots,
  getTimesForLounge,
  getSummary,
  createReservation
} from '../../../store/booking/booking.actions';
import {
  Lounge,
  TimeSlot,
  SummaryType
} from '../../../store/booking/booking.types';
import Select from '../../../components/FormField/Select';
import Button from '../../../components/Button';
import Sims from '../Sims';
import Slots from '../Slots';
import RidesSharing from '../RidesSharing';
import DropdownField from '../DropdownField';
import SearchField from '../../../components/SearchField';
import Summary from './Summary';
import { Settings } from 'luxon';
import './BookingForm.scss';
import Datepicker from '../Datepicker';
import NewCustomer from '../NewCustomer';

interface Props {
  loungeId: string;
  slotTime: string;
  initDate: Date;
  initSims: number;
  initRideSharing: boolean;
  initSlot: string | null;
  onSuccess: () => void;
}

interface Location {
  state: {
    id?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    isBooking?: boolean;
    [key: string]: any;
  };
  [key: string]: any;
}

const langs: any = {
  de,
  en,
  es
};

const BookingForm = ({
  loungeId,
  slotTime,
  initDate,
  initSims,
  initRideSharing,
  initSlot,
  onSuccess
}: Props) => {
  const shouldFetch = useRef<boolean>(true);
  const first = useRef<boolean>(true);
  const location = useLocation<Location>();
  const lounges = useWithSelection(loungeSelector);
  const { preferred_language } = useWithSelection(customerSelector);
  const [t, i18n] = useTranslation();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [lounge, setLounge] = useState<string>(loungeId);
  const [times, setTimes] = useState<string[]>([]);
  const [slots, setSlots] = useState<TimeSlot[]>([]);
  const [time, setTime] = useState<string>(slotTime);
  const [date, setDate] = useState<Date>(initDate);
  const [sims, setSims] = useState<number>(initSims);
  const [ridesSharing, setRidesSharing] = useState<boolean>(initRideSharing);
  const [slot, setSlot] = useState<string | null>(initSlot);
  const [summary, setSummary] = useState<SummaryType | null>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(false);
  const [newCustomer, setNewCustomer] = useState({
    first_name: '',
    last_name: '',
    email: ''
  });
  const [vat, setVat] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');

  if (loungeId) {
    let loungeObject = lounges.find((l: Lounge) => l.id === String(loungeId));
    // @ts-ignore
    Settings.defaultZone = loungeObject?.timezone?.toString();
  }

  const getLoungeSims = (loungeId: string) =>
    lounges.find((l: Lounge) => l.id === String(loungeId))?.max_group_size;

  const onLoungeChange = (name: string, value: string) => {
    setLounge(value);
  };

  const getSlots = async () => {
    try {
      const res = await getTimeSlots(lounge, sims);
      setSlots(res);
      if (!res.find((s: TimeSlot) => s.id === slot)) setSlot(null);
    } catch (err) { }
  };

  const createReservationTime = (date: Date, dateTime: string) => {
    // const startDate = format(new Date(date!), 'yyyy-MM-dd');
    // const offset = -addHours(new Date(startDate), 1).getTimezoneOffset() / 60;
    // const selectedTime = dateTime.split('T')[1].split('+')[0];
    // return `${startDate}T${selectedTime}+${`0${offset}`.substr(-2)}:00`;

    return dateTime;
  };

  const book = async () => {
    try {
      setSubmitting(true);
      await createReservation({
        time: createReservationTime(date!, time),
        lounge,
        slot,
        sims,
        customerId: selectedCustomerId,
        isNewCustomer,
        email: newCustomer.email,
        first_name: newCustomer.first_name,
        last_name: newCustomer.last_name,
        lang: preferred_language,
        rides_sharing: ridesSharing
      });
      onSuccess();
    } catch (error) {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (location.state?.isBooking) {
      setSelectedCustomerId(location.state.id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const selected = lounges.find((l: Lounge) => l.id === String(lounge));
    setVat(selected.lounge_owner.vat);
    setCurrency(selected.lounge_owner.currency);
    // eslint-disable-next-line
  }, [lounge]);

  useEffect(() => {
    if (isNewCustomer) setSelectedCustomerId(null);
    // eslint-disable-next-line
  }, [isNewCustomer]);

  useEffect(() => {
    if (!sims || !time || !date || !lounge || !slot) {
      setSummary(null);
      return;
    }

    (async (sims, lounge, slot, time, date, selectedCustomerId, ridesSharing) => {
      try {
        const data = await getSummary({
          start_time: `${format(new Date(date!), 'yyyy-MM-dd')}T${time.split('T')[1]
            }`,
          time_slot_id: slot,
          simulators: sims,
          lounge_id: lounge,
          customer_id: selectedCustomerId,
          rides_sharing: ridesSharing
        });
        setSummary(data);
      } catch (err) {
        setSummary(null);
      }
    })(sims, lounge, slot, time, date, selectedCustomerId, ridesSharing);
  }, [sims, lounge, slot, time, date, selectedCustomerId, ridesSharing]);

  useEffect(() => {
    if (first.current) return;

    if (sims > 1 && shouldFetch.current) {
      getSlots();
      shouldFetch.current = false;
    }
    if (sims === 1) {
      shouldFetch.current = true;
      getSlots();
    }
    // eslint-disable-next-line
  }, [sims]);

  useEffect(() => {
    if (!lounge) return;
    //get memberships and times for base center
    (async () => {
      try {
        const res = await Promise.all([
          getSlots(),
          getTimesForLounge(lounge, time ? time.toString() : '')
        ]);
        setTimes(res[1]);
        // check if base center has selected time
        if (time) {
          let timeObj = new Date(time);
          const timeExists = res[1].find((t: string) => {
            let tObj = new Date(t);
            return timeObj.getUTCHours() === tObj.getUTCHours() &&
              timeObj.getUTCMinutes() === tObj.getUTCMinutes();
          });
          if (!timeExists) setTime('');
        }
        first.current = false;
      } catch (err) { }
    })();
    // eslint-disable-next-line
  }, [lounge]);

  const selectedSlot = slots.find((s: TimeSlot) => s.id === slot);

  return (
    <div className="BookingForm">
      <div className="BookingForm__form">
        <h2>{t('booking.newBooking')}</h2>
        <Select
          name="lounge"
          i18nKey="profile.default_lounge_id"
          value={lounge}
          setFieldValue={onLoungeChange}
          list={lounges}
        />
        <SearchField
          onChangeCallback={() => setIsNewCustomer(false)}
          selected={selectedCustomerId}
          setSelected={setSelectedCustomerId}
          isNewCustomer={isNewCustomer}
        />
        <NewCustomer
          active={isNewCustomer}
          setActive={setIsNewCustomer}
          newCustomer={newCustomer}
          setNewCustomer={setNewCustomer}
        />
        <Datepicker label={t('booking.date')} date={date} setDate={setDate} />
        <DropdownField
          label={t('booking.time')}
          value={time}
          setValue={setTime}
          list={times}
        />
        <Sims
          sims={sims}
          setSims={setSims}
          available={getLoungeSims(lounge) || 1}
        />
        <Slots slots={slots} setSlot={setSlot} selected={slot} />
      </div>
      <div className="InvoiceSumnary">
        <h2>{t('booking.summary')}</h2>
        <div className="InvoiceSumnary__items">
          {slot && selectedSlot && (
            <div>
              <span>{t('booking.booking')}</span>
              <span>{selectedSlot.name}</span>
            </div>
          )}
          <div>
            <span>{t('booking.lounge')}</span>
            <span>
              {lounges.find((base: any) => base.id === lounge).name}
            </span>
          </div>
          {time && slot && selectedSlot && (
            <div>
              <span>{t('booking.time')}</span>
              <span>
                {format(new Date(time), 'HH:mm')} -{' '}
                {format(
                  addMinutes(new Date(time), selectedSlot.duration_in_minutes),
                  'HH:mm'
                )}
              </span>
            </div>
          )}
          {slot && selectedSlot && (
            <div>
              <span>{t('booking.duration')}</span>
              <span>{selectedSlot.duration_in_minutes} min</span>
            </div>
          )}
          <div>
            <span>{t('booking.sims')}</span>
            <span>x {sims}</span>
          </div>
          <div>
            <span>{t('booking.date')}</span>
            <span>
              {date &&
                format(date, 'EEEE, do MMMM', {
                  locale: langs[i18n.language]
                })}
            </span>
          </div>
          {(sims > 1) && (
            <div>
              <span>{t('calendar.ridesSharing')}</span>
              <span><RidesSharing ridesSharing={ridesSharing} setRidesSharing={setRidesSharing} groupRide={sims > 1} /></span>
            </div>
          )}
        </div>
        {summary && <Summary summary={summary} />}
        <div className="InvoiceSumnary__payment">
          <div className="InvoiceSumnary__total">
            <span>{t('booking.total')}</span>
            <span>
              {summary &&
                `${summary.currency} ${summary.final_price_sum.toFixed(2)}`}
            </span>
          </div>
          <div className="InvoiceSumnary__vat">
            <Trans i18nKey="booking.vatInfo" values={{ currency, vat }}>
              Prices are in {currency} including {vat}% Vat.
            </Trans>
          </div>
          <Button
            label={t('booking.bookNow')}
            onClick={book}
            submitting={submitting}
            disabled={
              !date ||
              !slot ||
              !time ||
              (!isNewCustomer && !selectedCustomerId) ||
              (isNewCustomer &&
                (!newCustomer.last_name ||
                  !newCustomer.first_name ||
                  !newCustomer.email))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
