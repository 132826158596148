import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from '../../../store/customer/customer.types';
import { verifyPin, login } from '../../../store/customer/customer.actions';
import Button from '../../../components/Button';
import { toastUtil } from '../../../utils/toast.utils';
import TextInput from '../../../components/FormField/TextInput';
import dispatch from '../../../hooks/useWithDispatch';
import pinIcon from '../../../images/Change PIN.svg';
import lockIcon from '../../../images/Change Password.svg';

interface Props {
  customer: Customer;
}

const Form = ({ customer }: Props) => {
  const verifyPinAction = dispatch(verifyPin);
  const loginAction = dispatch(login);
  const [t] = useTranslation();
  const [isPin, setIsPin] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [val, setVal] = useState<string>('');

  const togglePin = () => setIsPin(!isPin);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      if (isPin) {
        await verifyPinAction(customer, val);
        return;
      }
      await loginAction({ identifier: customer.email, password: val });
    } catch (error) {
      setSubmitting(false);
      toastUtil('error', error.meta.message);
    }
  };

  return (
    <div className="CustomerItem__form">
      <span>{customer.full_name}</span>
      <form onSubmit={handleSubmit}>
        <TextInput
          onChange={changeHandler}
          type="password"
          i18nKey={isPin ? 'auth.pin' : 'auth.login.password'}
          name="password"
          value={val}
        />
        <div className="CustomerItem__actions">
          <Button
            type="submit"
            submitting={submitting}
            label={t('auth.login.submit')}
            disabled={!val}
          />
          <span onClick={togglePin}>
            <img src={isPin ? lockIcon : pinIcon} alt="" />
          </span>
        </div>
      </form>
    </div>
  );
};

export default Form;
