import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SetStateType } from '../../../types/utils.types';
import icon from '../../../images/search.svg';
import './Search.scss';

interface Props {
  searchTerm: string;
  setSearchTerm: SetStateType<string>;
}

const Search = ({ searchTerm, setSearchTerm }: Props) => {
  const [t] = useTranslation();

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    val.length >= 3 ? setSearchTerm(val) : setSearchTerm('');
  };

  return (
    <div className="Search">
      <img src={icon} alt="" />
      <input placeholder={t('customers.search')} onChange={handleChange} />
    </div>
  );
};

export default Search;
