import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DayPicker from 'react-day-picker';
import { addMonths, format } from 'date-fns';
import en from '../../i18n/en/calendar';
import de from '../../i18n/de/calendar';
import es from '../../i18n/sp/calendar';
import { SetStateType } from '../../types/utils.types';
import { AvailableDatesResponse } from '../../store/booking/booking.types';
import Navbar from './Navbar';
import 'react-day-picker/lib/style.css';
import './Picker.scss';

const langs: any = {
  en,
  de,
  es
};

interface Props {
  onDayClick: (day: any, { selected }: any) => void;
  selected: Date | undefined;
  setSelectedMonth?: SetStateType<Date>;
  availableDates?: AvailableDatesResponse[];
  disablePast?: boolean;
}

interface RestProps {
  fromMonth?: any;
  disabledDays?: any;
}

const Picker = ({
  selected,
  onDayClick,
  setSelectedMonth,
  availableDates,
  disablePast
}: Props) => {
  const [, i18n] = useTranslation();
  const today = useMemo(() => new Date(), []);

  const renderDay = (day: Date) => {
    const formated = format(day, 'yyyy-MM-dd');
    const indicator =
      availableDates &&
      availableDates.find((d: AvailableDatesResponse) => d.date === formated);

    return (
      <>
        <span className="Picker__day">{day.getDate()}</span>
        {indicator && (
          <span
            className="Picker__indicator"
            style={{ background: indicator.color }}
          ></span>
        )}
      </>
    );
  };

  const onMonthChange = (date: Date) => {
    if (setSelectedMonth) setSelectedMonth(date);
  };

  const restProps: RestProps = {}

  if (disablePast) {
    restProps.fromMonth = today;
    restProps.disabledDays = { before: new Date() };
  }

  return (
    <div className="Picker">
      <DayPicker
        locale={i18n.language}
        weekdaysShort={langs[i18n.language].weekdaysShort}
        months={langs[i18n.language].months}
        month={selected}
        toMonth={addMonths(today, 12)}
        firstDayOfWeek={1}
        renderDay={renderDay}
        selectedDays={selected}
        onDayClick={onDayClick}
        onMonthChange={onMonthChange}
        navbarElement={<Navbar toMonth={addMonths(new Date(), 12)} disablePast={disablePast}/>}
        {
          ...restProps
        }
      />
    </div>
  );
};

export default Picker;
