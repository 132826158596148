import React, { ChangeEvent, useMemo } from 'react';
import { ReactComponent as Icon } from '../../../images/arrow-down-gray.svg';
import { SetStateType } from '../../../types/utils.types';
import { timeFromIsoDate } from '../../../utils/time.utils';
import './Filters.scss';

interface Option {
  name: string;
  id: number | string;
  [key: string]: any;
}

interface Props {
  label: string;
  value?: string;
  list?: Option[];
  timeList?: string[];
  setValue: SetStateType<string>;
  noEmptyValue?: boolean;
}

const FilterSelect = ({
  label,
  value,
  list,
  setValue,
  timeList,
  noEmptyValue = false
}: Props) => {
  const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    setValue(evt.target.value);
  };

  const options = useMemo(() => {
    if (!timeList) return list;
    let times: Option[] = timeList.map((t: string) => {
      return {
        id: t,
        name: timeFromIsoDate(t)
      };
    });
    return times;
  }, [list, timeList]);

  return (
    <div className="FilterSelect">
      <label>{label}</label>
      <div className="FilterSelect__wrapper">
        <select
          value={value}
          onChange={onChange}
          disabled={!options || !options.length}
        >
          {!noEmptyValue && <option value="" />}
          {options &&
            options.map((o: Option) => (
              <option value={o.id} key={o.id}>
                {o.name}
              </option>
            ))}
        </select>
        <Icon />
      </div>
    </div>
  );
};

export default FilterSelect;
