import {
  format,
  endOfMonth,
  startOfMonth,
  isSameMonth,
  isBefore
} from 'date-fns';
import {
  BookingActionTypes,
  GET_LOUNGE,
  Membership,
  TimeSlot,
  GetAvailableTimesDatesParams,
  GetReservationsResponse,
  AvailableTimesResponse,
  AvailableDatesResponse,
  ReservationDetails,
  InternalReservationDetails,
  GetSummaryArgs,
  SummaryType,
  CreateReservationArgs
} from './booking.types';
import { Dispatch } from 'react';
import { createCustomer } from '../customers/customers.actions';
import api, { API_ROOT } from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';

export const getLounge = async (
  dispatch: Dispatch<BookingActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/lap/lounges`);
    const loungeData = res.data.data;

    dispatch({
      type: GET_LOUNGE,
      payload: loungeData
    });
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getMemberships = async (
  loungeId?: string | number | null
): Promise<Membership[]> => {
  try {
    const q = loungeId ? `?lounge_Id=${loungeId}` : '';
    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/memberships${q}`);

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getTimeSlots = async (
  loungeId: string,
  sims: number,
  membership_id?: string
): Promise<TimeSlot[]> => {
  try {
    const {
      data: { data }
    } = await api.get(
      `${API_ROOT}/lap/time_slots?lounge_id=${loungeId}${
        membership_id ? `&membership_id=${membership_id}` : ''
      }&group_ride=${sims > 1 ? 'true' : 'false'}`
    );

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getTimesForLounge = async (
  loungeId: string, date: string
): Promise<string[]> => {
  try {
    const {
      data: { data }
    } = await api.get(
      `${API_ROOT}/lounges/${loungeId}/available_times?date=${date}`
    );

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getAvailableTimes = async ({
  selected_time,
  simulators,
  date,
  lounge_id,
  time_slot_id
}: GetAvailableTimesDatesParams): Promise<AvailableTimesResponse> => {
  try {
    let q = `date=${format(
      date,
      'yyyy-MM-dd'
    )}&lounge_id=${lounge_id}`;
    if (simulators) q += `&simulators=${simulators}`;
    if (selected_time) q += `&selected_time=${encodeURIComponent(selected_time)}`;
    if (time_slot_id) q += `&time_slot_id=${time_slot_id}`;

    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/available_times?${q}`);

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getAvailableDates = async ({
  selected_time,
  simulators,
  date,
  lounge_id,
  time_slot_id
}: GetAvailableTimesDatesParams): Promise<AvailableDatesResponse[]> => {
  try {
    const dateTo = format(endOfMonth(date), 'yyyy-MM-dd');
    let dateFrom: Date | string = startOfMonth(date);

    if (isSameMonth(dateFrom, new Date()) && isBefore(dateFrom, new Date())) {
      dateFrom = new Date();
    }
    dateFrom = format(dateFrom, 'yyyy-MM-dd');

    let q = `date_from=${dateFrom}&date_to=${dateTo}&lounge_id=${lounge_id}&time_slot_id=${time_slot_id}`;

    if (simulators) q += `&simulators=${simulators}`;
    if (selected_time) q += `&selected_time=${encodeURIComponent(selected_time)}`;

    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/available_dates?${q}`);

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getReservations = async (
  date: Date,
  lounge_id: string
): Promise<GetReservationsResponse> => {
  try {
    const {
      data: { data }
    } = await api.get(
      `${API_ROOT}/lap/reservations?date=${format(
        date,
        'yyyy-MM-dd'
      )}&lounge_id=${lounge_id}`
    );

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getReservationDetails = async (
  id: string
): Promise<ReservationDetails> => {
  try {
    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/reservations/${id}`);

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getInternalReservationDetails = async (
  id: string
): Promise<InternalReservationDetails> => {
  try {
    const {
      data: { data }
    } = await api.get(`${API_ROOT}/lap/internal_reservations/${id}`);

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const getSummary = async ({
  start_time,
  time_slot_id,
  simulators,
  lounge_id,
  customer_id,
  rides_sharing
}: GetSummaryArgs): Promise<SummaryType> => {
  try {
    let q = `start_time=${encodeURIComponent(start_time)}&time_slot_id=${time_slot_id}&simulators=${simulators}&lounge_id=${lounge_id}&rides_sharing=${rides_sharing}`;

    if (customer_id) q += `&customer_id=${customer_id}`;

    const {
      data: { data }
    } = await api(`/lap/reservations/summary_info?${q}`);

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const createReservation = async ({
  time,
  lounge,
  sims,
  slot,
  customerId,
  isNewCustomer,
  first_name,
  last_name,
  email,
  lang,
  rides_sharing
}: CreateReservationArgs): Promise<any> => {
  try {
    let customer = customerId;

    if (isNewCustomer) {
      const { id } = await createCustomer(
        first_name,
        last_name,
        email,
        lounge,
        lang,
        false
      );
      customer = id;
    }

    const {
      data: {
        meta: { message }
      }
    } = await api.post(`/lap/reservations`, {
      start_time: time,
      time_slot_id: slot,
      simulators: sims,
      lounge_id: lounge,
      customer_id: customer,
      rides_sharing: rides_sharing
    });

    toastUtil('success', message);
    return;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const checkIn = async (
  id: string,
  group_ride_customers?: number[]
): Promise<ReservationDetails> => {
  try {
    const {
      data: { data }
    } = await api.put(`${API_ROOT}/lap/reservations/${id}/check_in`, {
      group_ride_customers
    });

    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};
export const cancelBooking = async (id: string): Promise<any> => {
  try {
    await api.put(`${API_ROOT}/lap/reservations/${id}/cancel`);
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const applyCoupon = async (
  id: string,
  coupon_code: string
): Promise<ReservationDetails> => {
  try {
    const {
      data: { data }
    } = await api.post(`${API_ROOT}/lap/reservations/${id}/coupons`, {
      coupon_code
    });
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};

export const deleteCoupon = async (id: string): Promise<ReservationDetails> => {
  try {
    const {
      data: { data }
    } = await api.delete(`${API_ROOT}/lap/reservations/${id}/coupons`);
    return data;
  } catch (error) {
    toastUtil('error', error.response.data.meta.message);
    return Promise.reject(error.response.data);
  }
};
