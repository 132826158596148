import i18n from '../../i18n/index';
import api from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';

const options = {
  headers: {
    'x-locale': i18n.language
  }
};

export const getLoungeSimulators = async (
  id?: number | string,
  lang?: string
): Promise<any> => {
  try {
    const event = await api.get(`/simulators/by_lounge/${id}`, options);
    return event.data.data;
  } catch (err) {
    if (err.data && err.data.meta) toastUtil('error', err.data.meta.message);
  }
};