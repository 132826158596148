import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SummaryType,
  SummaryDiscount
} from '../../../store/booking/booking.types';

interface Props {
  summary: SummaryType;
}

interface ParsedDiscount {
  name: string;
  value: string;
}

const Summary = ({ summary }: Props) => {
  const [t] = useTranslation();

  const discounts = useMemo(() => {
    let parsedSummary = summary.products[0].discounts.reduce(
      (acc: any, d: SummaryDiscount) => {
        let price = acc.price;
        let discount: ParsedDiscount = {
          name: d.name,
          value: d.price_effect,
        };

        acc.discounts.push(discount);

        return { ...acc, price };
      },
      {
        price: summary.base_price_sum,
        discounts: []
      }
    );
    return parsedSummary.discounts;
  }, [summary]);

  return (
    <div className="Summary">
      <div>
        <span>{t('booking.price')}</span>
        <span>
          {summary.currency} {summary.base_price_sum.toFixed(2)}
        </span>
      </div>
      {discounts &&
        discounts.map((d: ParsedDiscount) => (
          <div key={d.name}>
            <span>{d.name}</span>
            <span>
              {summary.currency} {d.value}
            </span>
          </div>
        ))}
    </div>
  );
};

export default Summary;
