import React, { useState, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ParsedFreeSlot } from '../../../store/booking/booking.types';

interface Props {
  event: ParsedFreeSlot;
  onClick: (time: string) => void;
}

const FreeSlot: FunctionComponent<Props> = ({
  event: { left, width, height, top, time },
  onClick
}) => {
  const [t] = useTranslation();
  const [slotHeight, setSlotHeight] = useState(4.8);

  const handleClick = () => onClick(time);
  const onEnter = () => setSlotHeight(height);
  const onLeave = () => setSlotHeight(4.8);

  return (
    <div
      className="FreeSlot"
      style={{
        top: top,
        width: `${width}%`,
        height: `${slotHeight}rem`,
        left: `${left}%`
      }}
      onMouseEnter={() => {
        onEnter();
      }}
      onMouseLeave={() => {
        onLeave();
      }}
      onTouchStart={() => {
        onEnter();
      }}
      onTouchEnd={() => {
        onLeave();
      }}
      onClick={handleClick}
    >
      <span>+ {t('booking.newBooking')}</span>
    </div>
  );
};

export default FreeSlot;
