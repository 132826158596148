import { FormModelField } from '../../../types/form.types';

interface Values {
  first_name: string;
  last_name: string;
  email: string;
}

export const initialValues: Values = {
  first_name: '',
  last_name: '',
  email: ''
};

export const formModel: FormModelField[] = [
  {
    name: 'first_name',
    i18nKey: 'profile.first_name',
    type: 'text'
  },
  {
    name: 'last_name',
    i18nKey: 'profile.last_name',
    type: 'text'
  },
  {
    name: 'email',
    i18nKey: 'profile.email',
    type: 'text'
  }
];
