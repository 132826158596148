import React, { useEffect } from 'react';
import { SetStateType } from '../../../types/utils.types';
import './RidesSharing.scss';

interface RidesSharings {
  ridesSharing: boolean;
  setRidesSharing: SetStateType<boolean>;
  groupRide: boolean;
}

const RideSharing = React.memo(({ ridesSharing, setRidesSharing, groupRide }: RidesSharings) => {

  useEffect(() => {
    if (!groupRide) {
      setRidesSharing(false);
    }
  })

  return groupRide ? (
      <div className="toggle-switch small-switch">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          name="ridesSharing"
          checked={ridesSharing}
          onChange={e => setRidesSharing(e.target.checked)}
          id="ridesSharing"
        />
        <label className="toggle-switch-label" htmlFor="ridesSharing">
          <span className="toggle-switch-inner" />
          <span className="toggle-switch-switch" />
        </label>
      </div>
  ) : null;
});

export default RideSharing;
