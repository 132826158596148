const booking = {
  bookingDetails: 'Booking Details',
  internalReservationInfo: 'Internal Reservation',
  customer: 'Customer',
  lounge: 'Lounge',
  time: 'Time',
  duration: 'Duration',
  sims: 'Simulators',
  date: 'Date',
  total: 'Total',
  edit: 'Edit this booking',
  newBooking: 'New Booking',
  addNewCustomer: 'Add new customer',
  noResultsFor: 'No results for',
  summary: 'Summary',
  vatInfo: 'Prices are in {{currency}} including {{vat}}% Vat.',
  bookNow: 'Book now',
  booking: 'Booking',
  price: 'Price',
  note: 'Note',
  description: 'Description',
  paymentMethod: 'Payment Method',
  ridesSharing: 'Package Sharing',
  createdBy: 'Created By',
  checkIn: 'Check-In',
  checkedIn: 'Checked-In!',
  completed: 'Completed!',
  cancelTitle: 'Cancel This Booking?',
  cancelSubtitle:
    'Are you sure you want to cancel this booking? Once you cancel, you cannot reverse it.',
  yesConfirm: 'Yes, Confirm',
  viewDetails: 'View Details',
  cancel: 'Cancel',
  addOtherCustomers: 'Add Other customers',
  otherGroupCustomers: 'Enter bellow other customers to group ride:',
  bookedBy: 'Booked by',
  otherCustomers: 'Other customers',
  addCheckingCustomerError:
    'Customers were added. You need to remove one and try again.',
  done: 'Done',
  couponFormTitle: 'Enter Discount Code',
  couponSubtitle: 'Enter Discount Code for additional discounts',
  apply: 'Apply',
  couponInput: {
    label: 'Discount Code'
  }
};

export default booking;
