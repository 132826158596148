import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import arrowIcon from '../../images/arrow-down.svg';
import './Langs.scss';

const langs = [
  {
    text: 'EN',
    key: 'en'
  },
  {
    text: 'DE',
    key: 'de'
  },
  {
    text: 'ES',
    key: 'es'
  }
];

const Lang = () => {
  const ref = useRef(null);
  const [, i18next] = useTranslation();
  const [activeDropdown, setActiveDropdown] = useState<boolean>(false);

  const toggleDropdown = () => setActiveDropdown(!activeDropdown);
  const closeDropdown = () => {
    if (activeDropdown) setActiveDropdown(false);
  };

  useOnClickOutside(ref, closeDropdown);

  const changeLang = (lang: string): void => {
    i18next.changeLanguage(lang);
    closeDropdown();
  };

  return (
    <div ref={ref} className={cx('Langs', { 'is-active': activeDropdown })}>
      <span onClick={toggleDropdown}>
        {i18next.language} <img src={arrowIcon} alt="" />
      </span>
      <div>
        {langs.map((lang) => (
          <span
            key={lang.key}
            className={i18next.language === lang.key ? 'is-selected' : ''}
            onClick={() => changeLang(lang.key)}
          >
            <span>{lang.text}</span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Lang;
