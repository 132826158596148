import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import CloseDialog from '../../../components/CloseDialog';
import PinForm from './PinForm';

import lock from '../../../images/Change PIN.svg';

const ChangePass = () => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="ChangePass Profile__card" onClick={open}>
        <img src={lock} alt="" />
        <h4>{t('profile.changePinTitle')}</h4>
        <p>{t('profile.changePinText')}</p>
      </div>
      <Dialog onClose={close} open={isOpen} className="lap-dialog">
        <CloseDialog onClick={close} />
        <PinForm close={close} />
      </Dialog>
    </>
  );
};

export default ChangePass;
