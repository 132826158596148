import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import useOnClickOutside from 'use-onclickoutside';
import { isBefore, startOfDay } from 'date-fns';
import Picker from '../../../components/Picker';
import { SetStateType } from '../../../types/utils.types';
import icon from '../../../images/Calendar Light.svg';

import './Datepicker.scss';

interface Props {
  label: string;
  date: Date;
  setDate: SetStateType<Date>;
}

const Datepicker = ({ label, date, setDate }: Props) => {
  const ref = useRef(null);
  const [active, setActive] = useState<boolean>(false);

  const toggleDropdown = () => setActive(!active);
  const closeDropdown = () => {
    if (active) setActive(false);
  };

  const onDayClick = (day: any, { selected }: any) => {
    if (isBefore(startOfDay(day), startOfDay(new Date()))) return;
    setDate(selected ? undefined : day);
    closeDropdown();
  };

  useOnClickOutside(ref, closeDropdown);

  return (
    <div className="Datepicker" ref={ref}>
      <span className={cx('Datepicker__label', { value: date })}>{label}</span>
      <div
        className={cx('Datepicker__value', { active })}
        onClick={toggleDropdown}
      >
        {date && format(date, 'd/M/yyyy')}
        <img src={icon} alt="" />
      </div>
      {active && <Picker onDayClick={onDayClick} selected={date} disablePast/>}
    </div>
  );
};

export default Datepicker;
