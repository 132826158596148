import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScreenLock from './views/ScreenLock';
import Protected from './components/Protected';
import Login from './views/Login';
import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/switch-customers" component={ScreenLock} />
          <Route path="" component={Protected} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
