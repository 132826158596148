import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import { getCustomers } from '../../store/customers/customers.actions';
import { CustomerType } from '../../store/customers/customers.types';
import { SetStateType } from '../../types/utils.types';
import { ReactComponent as Icon } from '../../images/customer.svg';
import clearIcon from '../../images/cancel.svg';

import './SearchField.scss';

interface Props {
  selected: string | null;
  setSelected: SetStateType<string | null>;
  isNewCustomer?: boolean;
  onChangeCallback: () => void;
}

interface Location {
  state: {
    id?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    isBooking?: boolean;
    [key: string]: any;
  };
  [key: string]: any;
}

const SearchField = ({
  selected,
  setSelected,
  isNewCustomer,
  onChangeCallback
}: Props) => {
  const location = useLocation<Location>();
  const history = useHistory();
  const ref = useRef(null);
  const [t] = useTranslation();
  const [val, setVal] = useState<string>('');
  const [customers, setCustomers] = useState<CustomerType[] | null>(null);
  const [active, setActive] = useState<boolean>(false);

  const openDropdown = () => setActive(true);
  const closeDropdown = () => {
    if (active) setActive(false);
  };
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setSelected(null);
    setVal(evt.target.value);
    onChangeCallback();
  };

  const clear = () => {
    setVal('');
  };

  const handleCustomerSelect = (m: CustomerType) => {
    setSelected(m.id);
    setVal(`${m.first_name} ${m.last_name} (${m.email})`);
    closeDropdown();
  };

  useEffect(() => {
    if (location.state?.isBooking) {
      setVal(
        `${location.state.first_name} ${location.state.last_name} (${location.state.email})`
      );
      history.replace('/calendar');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (val.length < 3) {
      setCustomers(null);
      return;
    }

    (async () => {
      try {
        const { data } = await getCustomers({ search_term: val });
        setCustomers(data);
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [val]);

  useEffect(() => {
    if (isNewCustomer) setVal('');
    // eslint-disable-next-line
  }, [isNewCustomer]);

  useOnClickOutside(ref, closeDropdown);

  return (
    <div className="SearchField" ref={ref}>
      <span className={cx('SearchField__label', { value: !!val })}>
        {t('booking.customer')}
      </span>
      <Icon />
      <div className="SearchField__field">
        <input value={val} onChange={handleChange} onFocus={openDropdown} />
        {val && <img src={clearIcon} alt="" onClick={clear} />}
        <span className="SearchField__indicator" />
      </div>
      {customers && active && (
        <div className="SearchField__result">
          {customers.length > 0 ? (
            customers.map((m: CustomerType) => (
              <span
                key={m.id}
                className={cx({ selected: m.id === selected })}
                onClick={() => handleCustomerSelect(m)}
              >
                {m.first_name} {m.last_name} ({m.email})
              </span>
            ))
          ) : (
            <span className="SearchField__result__empty">
              {t('booking.noResultsFor')} {val}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchField;
