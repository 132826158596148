import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import {
  getMembershipsForCustomer,
  upgradeMembership,
  renewMembership
} from '../../../store/customers/customers.actions';
import { Plan } from '../../../store/customers/customers.types';
import PlansDropdown from './PlansDropdown';

interface Props {
  renew?: boolean;
  onSuccess: (newPlan: Plan) => void;
  curentPlan: Plan;
}

interface Params {
  id: string;
}

const MembershipForm = ({ renew, onSuccess, curentPlan }: Props) => {
  const { id } = useParams<Params>();
  const [t] = useTranslation();
  const [selected, setSelected] = useState<Plan | null>(
    renew ? curentPlan : null
  );
  const [list, setList] = useState<Plan[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const upgradePlan = async () => {
    try {
      if (!selected) return;
      setSubmitting(true);
      const res = renew
        ? await renewMembership(id)
        : await upgradeMembership(id, selected.id);
      onSuccess(res);
    } catch (err) {
      setSubmitting(false);
      return Promise.reject();
    }
  };

  useEffect(() => {
    if (renew) return;
    (async () => {
      try {
        const data = await getMembershipsForCustomer(id);
        setList(data);
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="MembershipForm">
      <div className="MembershipForm__inputs">
        <h2>
          {t(renew ? 'customers.renewMembership' : 'customers.upgradeMembership')}
        </h2>
        <h4>{t('customers.choosePackage')}</h4>
        <PlansDropdown
          setSelected={setSelected}
          selected={selected}
          plans={renew ? [curentPlan] : list}
        />
      </div>
      <div className="InvoiceSumnary">
        <h2>{t('booking.summary')}</h2>
        <div className="InvoiceSumnary__items">
          {selected && (
            <>
              <div>
                <span>{t('customers.package')}</span>
                <span>{selected.name}</span>
              </div>
              <div className="description">
                <span>{t('customers.description')}</span>
                <span>{selected.description}</span>
              </div>
            </>
          )}
        </div>
        <div className="InvoiceSumnary__payment">
          <div className="InvoiceSumnary__total">
            <span>{t('booking.total')}</span>
            <span>
              {selected && (
                <>
                  {selected.currency}{' '}
                  {renew ? selected.renewal_price! : selected.price}
                </>
              )}
            </span>
          </div>
          <div className="InvoiceSumnary__vat">{t('booking.vatInfo')}</div>
          <Button
            label={t('customers.confirm')}
            onClick={upgradePlan}
            submitting={submitting}
            disabled={!selected}
          />
        </div>
      </div>
    </div>
  );
};

export default MembershipForm;
