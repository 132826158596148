const auth = {
  login: {
    title: 'Sign In to Lounge Admin',
    subtitle: 'Enter your account details here.',
    identifier: {
      label: 'Email',
      required: 'This field is required'
    },
    password: {
      label: 'Password',
      required: 'This field is required'
    },
    rememberMe: 'Remeber me on this device',
    submit: 'Sign In',
    c2a: 'Problems logging in?'
  },
  loggedIn: 'Already logged in'
};

export default auth;
