import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SetStateType } from '../../../types/utils.types';
import './Sims.scss';

interface Simulators {
  available: number;
  sims: number;
  setSims: SetStateType<number>;
}

const Sims = React.memo(({ available, sims, setSims }: Simulators) => {
  const [t] = useTranslation();

  const addSim = () => {
    if (sims < available) setSims(sims + 1);
  };

  const removeSim = () => {
    if (sims > 1) setSims(sims - 1);
  };

  useEffect(() => {
    if (sims > available) setSims(1);
    // eslint-disable-next-line
  }, [sims, available]);

  return (
    <div className="Sims">
      <div>
        <span className="Sims__btn" onClick={removeSim} />
        <span className="Sims__counter">{sims}</span>
        <span className="Sims__btn __add" onClick={addSim} />
      </div>
      {t('calendar.sims')}
    </div>
  );
});

export default Sims;
