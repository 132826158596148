import React from 'react';
import logo from '../../images/racing-unleashed-mark.svg';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';
import { Lounge } from '../../store/booking/booking.types';
import { loungeSelector } from '../../store/booking/booking.selectors';
import CustomerMenu from './CustomerMenu';
import './Header.scss';

const Header = () => {
  const customer = useWithSelection(customerSelector);
  const lounges = useWithSelection(loungeSelector);

  const lounge = lounges.find((b: Lounge) => b.id === customer.default_lounge_id);

  return (
    <header className="Header">
      <div className="container">
        <div className="Header__logo">
          <img src={logo} alt="" />
          <div>
            Lounge Admin
            <span>{lounge && lounge.name}</span>
          </div>
        </div>
        <CustomerMenu />
      </div>
    </header>
  );
};

export default Header;
