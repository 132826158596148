import React, { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { CustomerType } from '../../../store/customers/customers.types';
import moreIcon from '../../../images/more.svg';

interface Props {
  customer: CustomerType;
}

const CustomerItem = ({ customer }: Props) => {
  const [t] = useTranslation();
  const ref = useRef(null);
  const [active, setActive] = useState<boolean>(false);

  const toggle = () => setActive(!active);
  const close = () => setActive(false);

  useOnClickOutside(ref, close);

  return (
    <div className="CustomerList__row">
      <div>
        <span className="CustomerList__avatar">
          <img src={customer.profile_pic_url} alt="" />
        </span>

        {customer.last_name}
      </div>
      <div>{customer.first_name}</div>
      <div>{customer.email}</div>
      <div>{customer.phone_number}</div>
      <div>
        {customer.current_membership_label}
        <div className={cx('Actions', { active })} ref={ref}>
          <span onClick={toggle}>
            <img src={moreIcon} alt="" />
          </span>
          {active && (
            <div>
              <Link to={`/${customer.id}`}>{t('customers.open')}</Link>
              <Link
                to={{
                  pathname: '/calendar',
                  state: {
                    id: customer.id,
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                    email: customer.email,
                    isBooking: true
                  }
                }}
              >
                {t('customers.makeBooking')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerItem;
