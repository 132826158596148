import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerType } from '../../../store/customers/customers.types';
import CustomerItem from './CustomerItem';

import './CustomerList.scss';

interface Props {
  customers: CustomerType[];
}

const CustomerList: FunctionComponent<Props> = ({ customers, children }) => {
  const [t] = useTranslation();

  return (
    <div className="CustomerList">
      <div className="CustomerList__wrapper">
        <div className="CustomerList__row __header">
          <div>{t('customers.lastName')}</div>
          <div>{t('customers.firstName')}</div>
          <div>{t('customers.email')}</div>
          <div>{t('customers.mobile')}</div>
          <div>{t('customers.currentMembership')}</div>
        </div>
        {customers.map((m: CustomerType) => (
          <CustomerItem customer={m} key={m.id} />
        ))}
      </div>
      {children}
    </div>
  );
};

export default CustomerList;
