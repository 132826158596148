const calendar = {
  filter: 'Filter',
  showSlots: 'Show Available Slots',
  sims: 'Simulators',
  lounge: 'Lounge',
  showFor: 'Show for',
  time: 'Time',
  durationInMin: 'Duration (in minutes)',
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ],
  weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  today: 'Today',
  ridesSharing: 'Paket Teilen',
  ridesSharingDisabled: 'Deaktiviert',
  sharedRides: 'Gemeinsame Fahrten'
};

export default calendar;
