import { FormModelField } from '../../types/form.types';

interface Values {
  identifier: string;
  password: string;
  remember_me: boolean;
}

export const initialValues: Values = {
  identifier: '',
  password: '',
  remember_me: false
};

export const formModel: FormModelField[] = [
  {
    name: 'identifier',
    i18nKey: 'auth.login.identifier',
    type: 'text'
  },
  {
    name: 'password',
    i18nKey: 'auth.login.password',
    type: 'password'
  }
];
