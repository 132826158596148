import React from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, subDays, format } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';
import useWithSelection from '../../../hooks/useWithSelection';
import { customerSelector } from '../../../store/customer/customer.selectors';
import { SetStateType } from '../../../types/utils.types';
import './CalendarNav.scss';

const langs: any = {
  de,
  en,
  es
};

interface Props {
  date: Date;
  setDate: SetStateType<Date>;
}

const CalendarNav = ({ date, setDate }: Props) => {
  const [t] = useTranslation();
  const { preferred_language } = useWithSelection(customerSelector);

  const setToday = () => setDate(new Date());
  const next = () => setDate(addDays(date, 1));
  const prev = () => {
    setDate(subDays(date, 1));
  };

  return (
    <div className="CalendarNav">
      <div className="CalendarNav__title">
        <span>
          {format(date, 'do MMMM', { locale: langs[preferred_language] })}
        </span>
        {format(date, 'EEEE', { locale: langs[preferred_language] })}
      </div>
      <div className="CalendarNav__actions">
        <span onClick={prev} />
        <span onClick={setToday}>{t('calendar.today')}</span>
        <span onClick={next} />
      </div>
    </div>
  );
};

export default CalendarNav;
