import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { BasicField } from './FormField';
import eye from '../../images/Eye.svg';
import eyeClosed from '../../images/Eye-Closed.svg';

const TextInput = ({
  value,
  onChange = () => {},
  i18nKey,
  errors = {},
  touched = {},
  name,
  type,
  onBlur = () => {},
  disabled
}: BasicField) => {
  const [isVisible, setIsVisible] = useState<boolean>(type === 'text');
  const toggleVisibility = () => setIsVisible(!isVisible);
  const [t] = useTranslation();
  const errorTrans = t(`${i18nKey}.${errors[name]}`);
  const errorMsg =
    errorTrans === `${i18nKey}.${errors[name]}` ? errors[name] : errorTrans;

  return (
    <div className={cx('FormField', name, { 'has-value': !!value })}>
      <div className="FormField-input">
        {i18nKey && <label>{t(`${i18nKey}.label`)}</label>}
        <input
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          type={type === 'password' && !isVisible ? 'password' : 'text'}
          disabled={disabled}
        />
        {type === 'password' && (
          <img
            src={isVisible ? eyeClosed : eye}
            onClick={toggleVisibility}
            alt=""
          />
        )}
        <span className="FormField-focusIndicator" />
      </div>
      {touched[name] && errors[name] && (
        <p className="FormField-error">{errorMsg}</p>
      )}
    </div>
  );
};

export default TextInput;
