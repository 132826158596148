import React from 'react';
import cx from 'classnames';
import { Customer } from '../../../store/customer/customer.types';
import { SetStateType } from '../../../types/utils.types';
import Form from './Form';
// import { setCurrentCustomer } from '../../../store/customer/customer.actions';
// import dispatch from '../../../hooks/useWithDispatch';

import './CustomerItem.scss';

interface Props {
  customer: Customer;
  selected: boolean;
  setSelected: SetStateType<string | null>;
}

const CustomerItem = ({ customer, setSelected, selected }: Props) => {
  // const setCustomerWithDispatch = dispatch(setCurrentCustomer);
  const onClick = () => {
    setSelected(customer.id);
  };

  return (
    <div className={cx('CustomerItem', { selected })} onClick={onClick}>
      <div className={cx('CustomerItem__header')}>
        <img src={customer.profile_pic_url} alt="" />
        <span>{customer.full_name}</span>
      </div>
      {selected && <Form customer={customer} />}
    </div>
  );
};

export default CustomerItem;
