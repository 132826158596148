export const CHANGE_LANG = 'CHANGE_LANG';
export const SET_CURRENT_MEMBER = 'SET_CURRENT_MEMBER';
export const REMOVE_CURRENT_MEMBER = 'REMOVE_CURRENT_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_CURRENT_MEMBER = 'UPDATE_CURRENT_MEMBER';

export interface Customer {
  id: string;
  email: string;
  nickname: string;
  first_name: string;
  last_name: string;
  full_name: string;
  profile_pic_url?: string;
  token: string;
  default_lounge_id: string;
  preferred_language: string;
  [key: string]: any;
}

export interface CustomerState {
  currentCustomer: Customer | null;
  customers: Customer[];
}

interface LogoutAction {
  type: typeof LOGOUT;
}

export interface SetCustomerAction {
  type: typeof SET_CURRENT_MEMBER;
  payload: Customer;
}

export interface LoginAction {
  type: typeof LOGIN;
  payload: Customer;
}

export interface UpdateCurrentCustomerAction {
  type: typeof UPDATE_CURRENT_MEMBER;
  payload: Customer;
}

export interface SetCurrentCustomer {
  type: typeof SET_CURRENT_MEMBER;
  payload: Customer;
}

export interface RemoveCurrentCustomer {
  type: typeof REMOVE_CURRENT_MEMBER;
}

interface ChangeLangAction {
  type: typeof CHANGE_LANG;
}

interface RemoveCustomerAction {
  type: typeof REMOVE_MEMBER;
  payload: number;
}

export type CustomerActionTypes =
  | LogoutAction
  | LoginAction
  | SetCustomerAction
  | ChangeLangAction
  | UpdateCurrentCustomerAction
  | SetCurrentCustomer
  | RemoveCurrentCustomer
  | RemoveCustomerAction;
