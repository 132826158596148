import React, { useState, FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseDialog from '../components/CloseDialog';
import BookingDetails from '../components/BookingDetails';
import { InvoicedPlan } from '../store/customers/customers.types';
import InvoiceMoreInfo from '../components/InvoiceMoreInfo/InvoiceMoreInfo';
import InternalReservationInfo from '../components/InternalReservationInfo/InternalReservationInfo';

interface BookingProviderValues {
  showDetails: (reservationId: string) => void;
  showInternal: (internalReservationId: string, srpInternal: boolean) => void;
  showInvoice: (invoice: InvoicedPlan) => void;
  closeDetails: () => void;
  closeInternal: () => void;
  closeInvoice: () => void;
  reservation: string | null;
  internalReservation: string | null;
  srpInternal: boolean;
  invoice: InvoicedPlan | null;
  openCancelDialog: () => void;
  closeCancelDialog: () => void;
  openCancel: boolean;
  setCheckedInId: (id: string) => void;
  checkedIn: string | null;
}

export const BookingContext = React.createContext<BookingProviderValues>({
  showDetails: (reservationId: string) => { },
  showInternal: (internalReservationId: string, srpInternal: boolean) => { },
  showInvoice: (invoice: InvoicedPlan) => { },
  closeDetails: () => { },
  closeInternal: () => { },
  closeInvoice: () => { },
  reservation: null,
  internalReservation: null, 
  srpInternal: false,
  invoice: null,
  openCancelDialog: () => { },
  closeCancelDialog: () => { },
  openCancel: false,
  checkedIn: null,
  setCheckedInId: (id: string) => { }
});

const BookingProvider: FunctionComponent = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openInternal, setOpenInternal] = useState<boolean>(false);
  const [openInvoice, setOpenInvoice] = useState<boolean>(false);
  const [srpInternal, setSrpInternal] = useState<boolean>(false);
  const [reservation, setReservation] = useState<null | string>(null);
  const [internalReservation, setInternalReservation] = useState<null | string>(null);
  const [invoice, setInvoice] = useState<null | InvoicedPlan>(null)
  const [openCancel, setOpenCancel] = useState<boolean>(false);
  const [checkedIn, setCheckedIn] = useState<string | null>(null);

  const openCancelDialog = () => {
    setOpenCancel(true);
    closeDetails();
  };
  const closeCancelDialog = () => {
    setOpenCancel(false);
  };

  const showDetails = (reservationId: string) => {
    setReservation(reservationId);
    setOpen(true);
  };

  const showInternal = (internalReservationId: string, srpInternal: boolean) => {
    setInternalReservation(internalReservationId);
    setSrpInternal(srpInternal);
    setOpenInternal(true);
  };

  const showInvoice = (invoice: InvoicedPlan) => {
    setInvoice(invoice);
    setOpenInvoice(true);
  };

  const closeDetails = () => {
    setOpen(false);
  };

  const closeInternal = () => {
    setOpenInternal(false);
  };

  const closeInvoice = () => {
    setOpenInvoice(false);
  };

  const setCheckedInId = (id: string) => {
    setCheckedIn(id);
  };

  return (
    <BookingContext.Provider
      value={{
        showDetails,
        showInternal,
        showInvoice,
        closeDetails,
        closeInternal,
        closeInvoice,
        reservation,
        internalReservation,
        srpInternal,
        invoice,
        openCancelDialog,
        closeCancelDialog,
        openCancel,
        setCheckedInId,
        checkedIn
      }}
    >
      {children}

      <Dialog onClose={closeDetails} open={open} className="ru-dialog">
        <CloseDialog onClick={closeDetails} />
        <BookingDetails />
      </Dialog>

      <Dialog onClose={closeInternal} open={openInternal} className="ru-dialog">
        <CloseDialog onClick={closeInternal} />
        <InternalReservationInfo />
      </Dialog>

      <Dialog onClose={closeInvoice} open={openInvoice} className="ru-dialog">
        <CloseDialog onClick={closeInvoice} />
        <InvoiceMoreInfo />
      </Dialog>
    </BookingContext.Provider>
  );
};

export default BookingProvider;
