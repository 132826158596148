import React, { useState, useContext, useEffect } from 'react';
import { ReservationDetails } from '../../store/booking/booking.types';
import { getReservationDetails } from '../../store/booking/booking.actions';
import { BookingContext } from '../../context/booking.ctx';
import GroupCheckin from './GroupCheckin';
import Details from './Details';
import CouponForm from './CouponForm';

import './BookingDetails.scss';

const BookingDetails = () => {
  const { reservation } = useContext(BookingContext);
  const [booking, setBooking] = useState<null | ReservationDetails>(null);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    if (!reservation) return;
    (async () => {
      try {
        const res = await getReservationDetails(reservation);
        setBooking(res);
      } catch (error) {}
    })();
  }, [reservation]);

  return (
    <div className="BookingDetails">
      { 
        page === 0 && 
          <Details
            booking={booking}
            setPage={setPage}
            setBooking={setBooking}
          />
      }
      {
        page === 1 &&
          <GroupCheckin
          booking={booking}
          setPage={setPage}
          setBooking={setBooking}
        />
      }
      {
        page === 2 &&
          <CouponForm 
            booking={booking}
            setPage={setPage}
            setBooking={setBooking}
          />
      }
    </div>
  );
};

export default BookingDetails;
