import React, {useState, ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../Button';
import TextInput from '../FormField/TextInput';
import { applyCoupon } from '../../store/booking/booking.actions';
import { ReservationDetails } from '../../store/booking/booking.types';
import { SetStateType } from '../../types/utils.types';

interface Props {
    booking: ReservationDetails | null;
    setPage: SetStateType<number>;
    setBooking: SetStateType<ReservationDetails | null>
}

const CouponForm = ({booking, setPage, setBooking}: Props) => {
    const [t] = useTranslation();
    const [value, setValue] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false)

    const changeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
        setValue(evt.target.value)
    }

    const onSubmit = async() => {
        try {
            setSubmitting(true)
            const data = await applyCoupon(booking?.id!, value)
            setBooking(data)
            setPage(0)
        } catch (error) {
            setSubmitting(false)
        }
    }

    return(
        <div className='BookingDetails__coupons'>
            <h2>{t('booking.couponFormTitle')}</h2>
            <h4>{t('booking.couponSubtitle')}</h4>
            <TextInput 
                value={value} 
                onChange={changeHandler} 
                name='coupon' 
                i18nKey='booking.couponInput'
                type='text'    
            />
            <Button onClick={onSubmit} label={t('booking.apply')} 
                    disabled={!value || submitting} 
                    submitting={submitting}
            />
        </div>
    )
}

export default CouponForm;