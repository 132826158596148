import React, { useMemo, useRef, useState, useEffect, useContext, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import CloseDialog from '../../../components/CloseDialog';
import {
  Lounge,
  Reservation,
  Event,
  ParsedEvent,
  ParsedFreeSlot,
  AvailableTimesState
} from '../../../store/booking/booking.types';
import { getReservations } from '../../../store/booking/booking.actions';
import { BookingContext } from '../../../context/booking.ctx';
import { SetStateType } from '../../../types/utils.types';
import GridItem from './GridItem';
import FreeSlot from './FreeSlot';
import BookingForm from '../BookingForm';
import { Settings, DateTime } from 'luxon';
import Cancel from './Cancel';
import './Grid.scss';

interface Props {
  date: Date;
  loungeId: string;
  availableTimes: AvailableTimesState;
  initSims: number;
  initRidesSharing: boolean;
  initSlot: string | null;
  onReservationSuccess: (duration?: number) => Promise<any>;
  setDate: SetStateType<Date>;
  selectSlotFromRow: (time: string) => void;
}

interface Location {
  state: {
    id?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    isBooking?: boolean;
    rideDetails?: boolean;
    start_time?: boolean;
    [key: string]: any;
  };
  [key: string]: any;
}

const Grid = ({
  loungeId,
  date,
  availableTimes,
  initSims,
  initRidesSharing,
  initSlot,
  onReservationSuccess,
  setDate,
  selectSlotFromRow
}: Props) => {
  const history = useHistory();
  const location = useLocation<Location>();
  const { showDetails, closeCancelDialog, openCancel, checkedIn } = useContext(
    BookingContext
  );
  const map = useRef(new Map());
  const [open, setOpen] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<string>('');
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [lounge, setLounge] = useState<Lounge | null>(null);

  if (lounge && lounge.timezone) {
    // @ts-ignore
    Settings.defaultZone = lounge.timezone.toString();;
  }

  const showForm = (time: string) => {
    setSelectedSlot(time);
    setOpen(true);
  };
  const closeForm = () => {
    setSelectedSlot('');
    setOpen(false);
  };
  const onSuccess = () => {
    closeForm();
    fetchReservations(date, loungeId);
    onReservationSuccess();
  };

  const onCancelSuccess = () => {
    fetchReservations(date, loungeId);
    closeCancelDialog();
  };

  const calcLoungeOpeningTime = useCallback((): DateTime => {
    if (!lounge) {
      return DateTime.now();
    }

    let loungeOpensAt = DateTime.fromISO(lounge.opens_at);

    if (reservations.length === 0 || reservations[0].start_time === "") {
      return loungeOpensAt;
    }

    let firstReservation = DateTime.fromISO(reservations[0].start_time);
    if (firstReservation && firstReservation < loungeOpensAt
      && loungeOpensAt.toLocaleString() === firstReservation.toLocaleString()
    ) {
      return firstReservation.set({ minute: 0, second: 0 });
    }

    return loungeOpensAt;
  }, [lounge, reservations])

  const calcLoungeClosingTime = useCallback((): DateTime => {
    if (!lounge) {
      return DateTime.now();
    }

    let loungeClosesAt = DateTime.fromISO(lounge.closes_at);

    if (reservations.length === 0 || reservations[reservations.length - 1].end_time === "") {
      return loungeClosesAt;
    }
    let lastReservation = DateTime.fromISO(reservations[reservations.length - 1].end_time);
    if (lastReservation && lastReservation > loungeClosesAt
      && loungeClosesAt.toLocaleString() === lastReservation.toLocaleString()
    ) {
      return lastReservation.set({ hour: (lastReservation.hour + 1), minute: 0, second: 0 });
    }

    return loungeClosesAt;
  }, [lounge, reservations])

  useEffect(() => {
    if (location.state?.isBooking) {
      setOpen(true);
    }
    if (location.state?.rideDetails) {
      showDetails(location.state.id);
      setDate(DateTime.fromISO(location.state.start_time).toJSDate());
      history.replace('/calendar');
    }
    // eslint-disable-next-line
  }, []);

  const parsedRows = useMemo(() => {
    if (!lounge) return null;
    const numOfRows: number =
      differenceInMinutes(
        calcLoungeClosingTime().toJSDate(),
        calcLoungeOpeningTime().toJSDate()
      ) / lounge.step;

    let currentRow = calcLoungeOpeningTime();
    let i: number = 1;
    let rows: string[] = [currentRow.toFormat('HH:mm')];

    while (i < numOfRows) {
      currentRow = currentRow.plus({ minutes: lounge.step });
      rows.push(currentRow.toFormat('HH:mm'));
      i++;
    }
    return rows;
  }, [lounge, calcLoungeClosingTime, calcLoungeOpeningTime]);

  const parsedBookings = useMemo(() => {
    if (!lounge) return null;
    let rows = map.current;
    const slotDuration = lounge.step;
    const loungeClose = calcLoungeClosingTime();
    const loungeOpens = calcLoungeOpeningTime();
    rows.clear();

    const internalEvents = reservations
      .filter((e: Event) => e.internal)
      .map((e: Event) => {
        const eventStart = DateTime.fromISO(e.start_time);
        const eventEnd = DateTime.fromISO(e.end_time);
        const start: string = eventStart.toFormat('HH:mm');
        var skippedSlotsCount: number = lounge.unavailable_slots &&
          lounge.unavailable_slots.filter((s) => s < start).length
        skippedSlotsCount = skippedSlotsCount > 0 ? skippedSlotsCount - 1 : 0;
        var normalisedEventSimulatos = e.simulators > lounge.simulators_count ? lounge.simulators_count : e.simulators;
        const currentEvent: ParsedEvent = {
          ...e,
          width: (normalisedEventSimulatos / lounge.simulators_count) * 100,
          height: Math.ceil(e.time_duration / slotDuration) * 4.8,
          top:
            (Math.ceil(
              differenceInMinutes(eventStart.toJSDate(), loungeOpens.toJSDate()) / lounge.step
            ) - skippedSlotsCount) * 48,
          left: 0,
          reverseOrder: false
        };
        
        const eventRows: number = Math.ceil(
          currentEvent.time_duration / slotDuration
        );

        let nextRowDT = DateTime.fromISO(e.start_time);
        let diff = Math.abs(nextRowDT.minute - (Math.ceil(nextRowDT.minute / lounge.step) * lounge.step));
        let nextRow = nextRowDT.plus({ minute: diff }).toFormat('HH:mm');

        if (!rows.has(nextRow)) {
          rows.set(nextRow, [currentEvent]);

          expandToRow(currentEvent, eventRows);
        } else {
          let row = rows.get(nextRow);
          if (
            eventRows > 1 &&
            row[0].start_time !== currentEvent.start_time &&
            !row[0].reverseOrder
          ) {
            currentEvent.left = 100 - currentEvent.width;
            currentEvent.reverseOrder = true;
          }

          row.forEach((eventInRow: ParsedEvent) => {
            if (collidesWith(currentEvent, eventInRow)) {
              currentEvent.left = currentEvent.reverseOrder
                ? eventInRow.left - currentEvent.width
                : eventInRow.left + eventInRow.width;
            }
          });
          rows.set(nextRow, [...row, currentEvent]);

          expandToRow(currentEvent, eventRows);
        }

        if (currentEvent.height < 0) currentEvent.height = 4.8;
        if (currentEvent.top < 0) currentEvent.top = 0;
        var nextOpeningTime = lounge.opening_times && lounge.opening_times.find((s) => start < s);
        var eventStartTime = eventStart.toFormat('HH:mm');
        if (nextOpeningTime && eventStartTime < nextOpeningTime) {
          var correctedStartTime = eventStart;
          // correctedStartTime.setHours();
          // correctedStartTime.setMinutes();

          correctedStartTime.set({ hour: parseInt(nextOpeningTime.split(':')[0]), minute: parseInt(nextOpeningTime.split(':')[1]) });

          currentEvent.height = (differenceInMinutes(eventEnd.toJSDate(), correctedStartTime.toJSDate()) / slotDuration) * 4.8;
        }

        if (eventEnd > loungeClose)
          currentEvent.isAtBottom = true;

        return currentEvent;
      });

    const events = reservations
      .filter((e: Event) => !e.internal)
      .sort((a: Event, b: Event) => {
        const startA = DateTime.fromISO(a.start_time);
        const startB = DateTime.fromISO(b.start_time);
        if (startA < startB) return -1;
        if (startA > startB) return 1;
        if (a.time_duration < b.time_duration) return 1;
        if (a.time_duration > b.time_duration) return -1;

        return 0;
      })
      .map((e: Event) => {
        const start: string = DateTime.fromISO(e.start_time).toFormat('HH:mm');
        var skippedSlotsCount: number = lounge.unavailable_slots &&
          lounge.unavailable_slots.filter((s) => s < start).length
        skippedSlotsCount = skippedSlotsCount > 0 ? skippedSlotsCount - 1 : 0;
        const currentEvent: ParsedEvent = {
          ...e,
          width: (e.simulators / lounge.simulators_count) * 100,
          height: Math.ceil(e.time_duration / slotDuration) * 4.8,
          top:
            (Math.ceil(
              differenceInMinutes(DateTime.fromISO(e.start_time).toJSDate(), loungeOpens.toJSDate()) / lounge.step
            ) - skippedSlotsCount) * 48,
          left: 0,
          reverseOrder: false
        };

        const eventRows: number = Math.ceil(
          currentEvent.time_duration / slotDuration
        );

        let nextRowDT = DateTime.fromISO(e.start_time);
        let diff = Math.abs(nextRowDT.minute - (Math.ceil(nextRowDT.minute / lounge.step) * lounge.step));
        let nextRow = nextRowDT.plus({ minute: diff }).toFormat('HH:mm');

        if (!rows.has(nextRow) ||
          rows.get(nextRow).every((v: { [x: string]: boolean; }) => v["internal"] === true)) {
          rows.set(nextRow, [currentEvent]);

          expandToRow(currentEvent, eventRows);
        } else {
          let row = rows.get(nextRow);
          if (
            eventRows > 1 &&
            row[0].start_time !== currentEvent.start_time &&
            !row[0].reverseOrder
          ) {
            currentEvent.left = 100 - currentEvent.width;
            currentEvent.reverseOrder = true;
          }

          row.forEach((eventInRow: ParsedEvent) => {
            if (collidesWith(currentEvent, eventInRow)) {
              currentEvent.left = currentEvent.reverseOrder
                ? eventInRow.left - currentEvent.width
                : eventInRow.left + eventInRow.width;
            }
          });
          rows.set(nextRow, [...row, currentEvent]);

          expandToRow(currentEvent, eventRows);
        }

        const eventStart = DateTime.fromISO(e.start_time);
        const eventEnd = DateTime.fromISO(e.end_time);
        var nextOpeningTime = loungeOpens.toFormat('HH:mm');
        if (differenceInMinutes(loungeOpens.toJSDate(), eventStart.toJSDate()) > 1) {
          var correctedStartTime = eventStart;
          correctedStartTime = correctedStartTime.set({ hour: parseInt(nextOpeningTime.split(':')[0]), minute: parseInt(nextOpeningTime.split(':')[1]) });

          currentEvent.height = (differenceInMinutes(eventEnd.toJSDate(), correctedStartTime.toJSDate()) / slotDuration) * 4.8;
          currentEvent.top = (differenceInMinutes(correctedStartTime.toJSDate(), loungeOpens.toJSDate()) / slotDuration) * 4.8;
        }

        return currentEvent;
      });

    const freeSlots = availableTimes.times.map((t: string) => {
      const start: string = DateTime.fromISO(t).toFormat('HH:mm');
      var skippedSlotsCount: number = lounge.unavailable_slots &&
        lounge.unavailable_slots.filter((s) => s < start).length
      skippedSlotsCount = skippedSlotsCount > 0 ? skippedSlotsCount - 1 : 0;
      const slot: ParsedFreeSlot = {
        time: t,
        width: (availableTimes.sims / lounge.simulators_count) * 100,
        height: Math.ceil(availableTimes.duration / slotDuration) * 4.8,
        top:
          (Math.ceil(
            differenceInMinutes(DateTime.fromISO(t).toJSDate(), loungeOpens.toJSDate()) / lounge.step
          ) - skippedSlotsCount) * 48,
        left: 0
      };

      let nextRowDT = DateTime.fromISO(t);
      let diff = Math.abs(nextRowDT.minute - (Math.ceil(nextRowDT.minute / lounge.step) * lounge.step));
      let nextRow = nextRowDT.plus({ minute: diff }).toFormat('HH:mm');

      if (!rows.has(nextRow)) {
        rows.set(nextRow, [slot]);
      } else {
        let row = rows.get(nextRow);

        row.forEach((eventInRow: ParsedEvent) => {
          if (collidesWith(slot, eventInRow)) {
            slot.left = eventInRow.left + eventInRow.width;
          }
        });
        rows.set(nextRow, [...row, slot]);
      }

      if (lounge.unavailable_slots && lounge.unavailable_slots.length
        && lounge.unavailable_slots.indexOf(nextRow) !== -1) {
        return null;
      }

      return slot;
    });
    return { events, freeSlots, internalEvents };
    // eslint-disable-next-line
  }, [reservations, lounge, date, availableTimes]);

  function collidesWith(
    a: ParsedEvent | ParsedFreeSlot,
    b: ParsedEvent | ParsedFreeSlot
  ) {
    const aStart = a.left;
    const bStart = b.left;
    const aEnd = a.left + a.width;
    const bEnd = b.left + b.width;

    if (aEnd > bStart && aEnd <= bEnd) return true;
    if (aStart >= bStart && aStart < bEnd) return true;
    return false;
  }

  function expandToRow(currentEvent: ParsedEvent, eventRows: number) {
    if (!lounge) return;
    const rows = map.current;

    let currentRow = 1;

    while (currentRow < eventRows) {
      let nextRowDT = DateTime.fromISO(currentEvent.start_time).plus({ minute: (currentRow * lounge.step) });
      let diff = Math.abs(nextRowDT.minute - (Math.ceil(nextRowDT.minute / lounge.step) * lounge.step));
      let nextRow = nextRowDT.plus({ minute: diff }).toFormat('HH:mm');

      if (!rows.has(nextRow)) {
        rows.set(nextRow, [currentEvent]);
      } else {
        let row = rows.get(nextRow);
        rows.set(nextRow, [...row, currentEvent]);
      }
      currentRow++;
    }
  }

  const fetchReservations = async (
    date: Date,
    loungeId: string
  ): Promise<any> => {
    try {
      const { reservations, ...data } = await getReservations(
        date,
        loungeId
      );
      setReservations(reservations);
      setLounge(data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchReservations(date, loungeId);
  }, [date, loungeId, checkedIn]);

  if (!lounge) return null;

  return (
    <>
      <div className="Grid">
        <div className="Grid__slots">
          {parsedRows &&
            parsedRows.map((slot: string) => (
              <div
                key={slot}
                className={`Grid__slot Grid__row ${lounge.unavailable_slots && lounge.unavailable_slots.length
                  && lounge.unavailable_slots.indexOf(slot) !== -1 ? 'Grid__slot__unavailable' : ''}`}>
                <div>{slot}</div>
              </div>
            ))}
        </div>
        <div className="Grid__events">
          {parsedRows &&
            parsedRows.map((row: string) => (
              <span
                key={row}
                className={`Grid__row ${lounge.unavailable_slots && lounge.unavailable_slots.length
                  && lounge.unavailable_slots.indexOf(row) !== -1 ? 'Grid__row__unavailable' : ''}`}
                onClick={() => {
                  let t: any = lounge.closes_at.split('T');
                  t[1] = `${row}${t[1].substring(5)}`;
                  t = t.join('T');
                  selectSlotFromRow(t);
                }}
              />
            ))}
          {parsedBookings &&
            parsedBookings.internalEvents.map(
              (e: ParsedEvent | null) => e && <GridItem key={e.id} event={e} />
            )}
          {parsedBookings &&
            parsedBookings.events.map(
              (e: ParsedEvent | null) => e && <GridItem key={e.id} event={e} />
            )}

          {parsedBookings &&
            parsedBookings.freeSlots.map(
              (e: ParsedFreeSlot | null) =>
                e && (
                  <FreeSlot
                    key={`${e.left}+${e.top}`}
                    event={e}
                    onClick={showForm}
                  ></FreeSlot>
                )
            )}
        </div>
      </div>
      <Dialog onClose={closeCancelDialog} open={openCancel}>
        <CloseDialog onClick={closeCancelDialog} />
        <Cancel onSuccess={onCancelSuccess} />
      </Dialog>
      <Dialog onClose={closeForm} open={open}>
        <CloseDialog onClick={closeForm} />
        <BookingForm
          onSuccess={onSuccess}
          loungeId={loungeId}
          slotTime={selectedSlot}
          initDate={date}
          initSims={initSims}
          initRideSharing={initRidesSharing}
          initSlot={initSlot}
        />
      </Dialog>
    </>
  );
};

export default Grid;
