import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CloseDialog from '../../../components/CloseDialog';
import { SetStateType } from '../../../types/utils.types';
import { loungeSelector } from '../../../store/booking/booking.selectors';
import { Lounge } from '../../../store/booking/booking.types';
import useWithSelection from '../../../hooks/useWithSelection';
import Option from '../Option';

interface Props {
  base: string | null;
  setBase: SetStateType<string | null>;
}

const BaseFilter = ({ base, setBase }: Props) => {
  const [t] = useTranslation();
  const bases = useWithSelection(loungeSelector);
  const [open, setOpen] = useState<boolean>(false);

  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  return (
    <>
      <span className={cx('Customers__pill', { active: !!base })} onClick={show}>
        {!base && t('customers.allLounges')}
        {base && bases.find((b: Lounge) => b.id === base).name}
      </span>
      <Dialog onClose={hide} open={open} className="ru-dialog">
        <CloseDialog onClick={hide} />
        <div className="Customers__filter-options">
          <h2>{t('customers.chooseLounge')}</h2>
          <Option
            value={null}
            setValue={setBase}
            callback={hide}
            isSelected={!base}
          >
            {t('customers.allLounges')}
          </Option>
          {bases.map((b: Lounge) => (
            <Option
              setValue={setBase}
              value={b.id}
              callback={hide}
              isSelected={b.id === base}
              key={b.id}
            >
              {b.name}
            </Option>
          ))}
        </div>
      </Dialog>
    </>
  );
};

export default BaseFilter;
