export const GET_CODE = 'GET_CODE';

export interface Code {
  id: string;
  name: string;
}

export interface CodeState {
  code: Code[] | [];
}

interface GetCodeAction {
  type: typeof GET_CODE;
  payload: Code[];
}

export type CodeActionTypes = GetCodeAction;
