import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import useWithSelection from '../../hooks/useWithSelection';
import dispatch from '../../hooks/useWithDispatch';
import { customersSelector, customerSelector } from '../../store/customer/customer.selectors';
import { removeCurrentCustomer, getCustomer } from '../../store/customer/customer.actions';
import { getLounge } from '../../store/booking/booking.actions';
import BookingProvider from '../../context/booking.ctx';
import Header from '../Header';
import Profile from '../../views/Profile';
import Calendar from '../../views/Calendar';
import Customers from '../../views/Customers';
import CustomerProfile from '../../views/CustomerProfile';
import { getGenderCodes } from '../../store/code/code.actions';
import { getCountries } from '../../store/country/country.actions';

const Protected = () => {
  const customers = useWithSelection(customersSelector);
  const currentCustomer = useWithSelection(customerSelector);
  const removeCustomer = dispatch(removeCurrentCustomer);
  const loungeDispatch = dispatch(getLounge);
  const genderCodeDispatch = dispatch(getGenderCodes);
  const countriesDispatch = dispatch(getCountries);
  const me = dispatch(getCustomer);

  useEffect(() => {
    loungeDispatch();
    genderCodeDispatch();
    countriesDispatch();
    me();

    const activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart'
    ];

    let activityCounter: number = 0;
    let activityInterval = setInterval(() => {
      if (activityCounter > 900) {
        removeCustomer();
      }
      activityCounter += 1;
    }, 1000);

    const activityWatcher = () => {
      activityCounter = 0;
    };

    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, activityWatcher, true);
    });

    return () => {
      activityEvents.forEach(function (eventName) {
        document.removeEventListener(eventName, activityWatcher, true);
      });
      if (activityInterval) clearInterval(activityInterval);
    };
    // eslint-disable-next-line
  }, []);

  if (!currentCustomer && customers.length) return <Redirect to="/switch-customers" />;
  if (!customers.length) return <Redirect to="/login" />;

  return (
    <BookingProvider>
      <Header />
      <div className="container">
        <Switch>
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/calendar" component={Calendar} />
          <Route exact path="/" component={Customers} />
          <Route exact path="/:id" component={CustomerProfile} />
        </Switch>
      </div>
    </BookingProvider>
  );
};

export default Protected;
