import React from 'react';
import { isBefore, startOfMonth, isAfter } from 'date-fns';

const Navbar = ({
  previousMonth,
  nextMonth,
  onPreviousClick,
  onNextClick,
  toMonth,
  fromMonth,
  disablePast
}: {
  [key: string]: any;
}) => {
  const showNextMonth = () => onNextClick();

  const showPrevMonth = () => onPreviousClick();

  const disablePrev = disablePast && isBefore(
    startOfMonth(previousMonth),
    startOfMonth(fromMonth ? fromMonth : new Date())
  );

  const disableNext = toMonth
    ? isAfter(startOfMonth(nextMonth), startOfMonth(toMonth))
    : false;

  return (
    <div className="Picker__Navbar">
      <span
        className={disablePrev ? 'is-disabled' : ''}
        onClick={showPrevMonth}
      ></span>
      <span
        className={disableNext ? 'is-disabled' : ''}
        onClick={showNextMonth}
      ></span>
    </div>
  );
};

export default Navbar;
