import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseDialog from '../../components/CloseDialog';
import { getCustomers } from '../../store/customers/customers.actions';
import { CustomerType, Paging } from '../../store/customers/customers.types';
import CustomerList from './CustomerList';
import CustomersHeader from './CustomersHeader';
import CustomersFooter from '../../components/CustomersFooter';
import BaseFilter from './BaseFilter';
import FilterWith from './FilterWith';
import MembershipFilter from './MembershipFilter';
import Search from './Search';
import CustomerForm from './CustomerForm';

import './Customers.scss';

const Customers = () => {
  const first = useRef(true);
  const [customers, setCustomers] = useState<CustomerType[]>([]);
  const [meta, setMeta] = useState<Paging | null>(null);
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [base, setBase] = useState<string | null>(null);
  const [filterWith, setFilterWith] = useState<string>('');
  const [membership, setMembership] = useState<string | null>(null);
  const [size, setSize] = useState<number>(12);
  const [open, setOpen] = useState<boolean>(false);

  const openForm = () => {
    setOpen(true);
  };
  const closeForm = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    fetchCustomers();
    closeForm();
  };

  const fetchCustomers = async () => {
    try {
      const { data, meta } = await getCustomers({
        search_term: searchTerm,
        default_lounge_id: base,
        membership_id: membership,
        filter_with: filterWith,
        page,
        size
      });
      setCustomers(data);
      setMeta(meta);
    } catch (error) {}
  };

  useEffect(() => {
    if (first.current) return;
    page === 1 ? fetchCustomers() : setPage(1);
    // eslint-disable-next-line
  }, [searchTerm, base, membership, filterWith, size]);

  useEffect(() => {
    first.current = false;
    fetchCustomers();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <div className="Customers">
        <CustomersHeader
          numOfCustomers={meta?.total_count || 0}
          openForm={openForm}
        />
        <div className="Customers__filters-wrapper">
          <BaseFilter base={base} setBase={setBase} />
          <MembershipFilter
            membership={membership}
            setMembership={setMembership}
            base={base}
          />
          <FilterWith filterWith={filterWith} setFilterWith={setFilterWith} />
          <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
        <CustomerList customers={customers}>
          <CustomersFooter
            paging={meta}
            setPage={setPage}
            setSize={setSize}
            size={size}
            page={page}
          />
        </CustomerList>
      </div>
      <Dialog onClose={closeForm} open={open}>
        <CloseDialog onClick={closeForm} />
        <CustomerForm onSuccess={onSuccess} />
      </Dialog>
    </>
  );
};

export default Customers;
