import React, { useState, ChangeEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { getNote, postNote } from '../../../store/customers/customers.actions';

import './Notes.scss';

interface Params {
  id: string;
}

const Notes = () => {
  const { id } = useParams<Params>();
  const [t] = useTranslation();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [val, setVal] = useState<string>('');

  const changeHandler = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setVal(evt.target.value);
  };

  const save = async () => {
    try {
      setSubmitting(true);
      await postNote(id, val);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const res = await getNote(id);
        setVal(res.content);
      } catch (error) {}
    })();
  }, [id]);

  return (
    <div className="Notes">
      <div className="Notes__header">
        <h3>{t('customers.notes')}</h3>
        <Button
          type="submit"
          label={t('customers.save')}
          disabled={submitting}
          submitting={submitting}
          small
          onClick={save}
        />
      </div>
      <div className="Notes__body">
        <textarea
          value={val}
          onChange={changeHandler}
          placeholder={t('customers.notesPlaceholder')}
        />
      </div>
    </div>
  );
};

export default Notes;
