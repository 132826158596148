import React from 'react';
import { useTranslation } from 'react-i18next';
import { SetStateType } from '../../../types/utils.types';
import { TimeSlot } from '../../../store/booking/booking.types';
import './Slots.scss';

interface Props {
  selected: string | null;
  slots: TimeSlot[];
  setSlot: SetStateType<string | null>;
}

const Slot = React.memo(({ selected, slots, setSlot }: Props) => {
  const [t] = useTranslation();

  return (
    <div className="Slots">
      <span>{t('calendar.durationInMin')}</span>
      <div className="Slots__wrapper">
        {slots.map((s: TimeSlot) => (
          <span
            key={s.id}
            onClick={() => setSlot(s.id)}
            className={selected === s.id ? 'selected' : ''}
          >
            {s.duration_in_minutes}
          </span>
        ))}
      </div>
    </div>
  );
});

export default Slot;
