import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikHelpers, FormikValues } from 'formik';
import { customerSelector } from '../../../store/customer/customer.selectors';
import { createCustomer } from '../../../store/customers/customers.actions';
import useWithSelection from '../../../hooks/useWithSelection';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button';
import { formModel, initialValues } from './formModel';
import validationSchema from './validationSchema';

import './CustomerForm.scss';

interface Props {
  onSuccess: () => void;
}

const CustomerForm = ({ onSuccess }: Props) => {
  const [t] = useTranslation();
  const customer = useWithSelection(customerSelector);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        await createCustomer(
          values.first_name,
          values.last_name,
          values.email,
          customer.default_lounge_id,
          customer.preferred_language,
          true
        );
        onSuccess();
      } catch (err) {
        setSubmitting(false);
      }
    }
  });

  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <div className="CustomerForm">
      <h2>{t('customers.newCustomer')}</h2>
      <form onSubmit={formik.handleSubmit}>
        {formModel.map((field) => (
          <FormField
            key={field.name}
            i18nKey={field.i18nKey}
            type={field.type}
            errors={formik.errors}
            touched={formik.touched}
            setFieldValue={formik.setFieldValue}
            {...formik.getFieldProps(field.name)}
          />
        ))}
        <div>
          <Button
            type="submit"
            label={t('customers.createNewCustomer')}
            disabled={submitting || !isValidForm}
            submitting={submitting}
          />
        </div>
      </form>
    </div>
  );
};

export default CustomerForm;
